import React from 'react'
import { Button } from 'components/form/buttons/Button'
import { OtColors } from 'util/OtColors'
import {
	AddOutlinePointIcon,
	CurveOutlineIcon,
	LineOutlineIcon,
	DeleteOutlinePointIcon,
	MoveIcon,
} from 'components/common/icons/editor/EditorSvgIcons'
// import * as ICON from  'components/common/icons/SvgIcons';
import {
	ArrowIcon,
	CartIcon,
	CheckIcon,
	ChevronIcon,
	CloseIcon,
	PopUpCloseIcon,
	FilterIcon,
	HelpIcon,
	HomeIcon,
	LoginIcon,
	LoginIconMobile,
	MenuIcon,
	MinusIcon,
	MobileCalendarIcon,
	MobilePackagesIcon,
	MobileDonationsIcon,
	MobileProductsIcon,
	MobileGiftCardsIcon,
	PencilIcon,
	PlusIcon,
	PromoIcon,
	TrashIcon,
	UserIcon,
	TextIcon,
} from 'components/common/icons/SvgIcons'

import 'sass/components/form/buttons/_iconButtons.scss'

const PADDING = '5px'
const DOUBLE_PADDING = '10px'
/*
 iconPosition:  top, right, botom, left
 Indicates icon's position related to label. If there is no label, iconPosition will be by default center;
*/
/* To customize color of icon use props: className="ot_custom_fill" */
/* labels={I18n.getKey(Checkout.Common.city)} */

/* svg color is given by button type.
it can be modified inside it's parent class with class .ot_svg_fill.
it may be passed as color if necessary
 */

export class IconButton extends Button {
	static defaultClassName = 'ot_iconButton'
	constructor(props) {
		super(props, IconButton.defaultClassName)
	}
	render() {
		let {
			alignText,
			buttonType,
			bounded,
			children,
			className,
			color,
			disabled,
			direction,
			hovered,
			iconPosition = 'left',
			isConditional,
			label,
			onClick,
			selected,
			showIcon,
			size,
			style,
			tag,
			value,
			...props
		} = this.props
		let TagName = 'button'
		if (tag) TagName = tag.toLowerCase()

		let classes = 'btn ot_iconButton'

		if (disabled) classes += ' disabled'
		else if (selected) classes += ' selected'
		else classes += ' unselected'

		if (!disabled && hovered) classes += ' hovered'
		if (bounded) classes = 'btn ot_iconButton-bounded'
		if (buttonType) {
			switch (buttonType.toLowerCase()) {
				case 'default':
					classes += ' ot_defaultButton'
					break
				case 'link':
					classes += ' ot_linkButton'
					break
				case 'primary':
					classes += ' ot_primaryButton'
					break
				case 'secondary':
					classes += ' ot_secondaryButton'
					break
				case 'transparent':
					classes += ' ot_transparentButton'
					break
				case 'navbar':
					classes += ' ot_navbarButton'
					break
				case 'menu':
					classes += ' ot_menuButton'
					break
				case 'circular':
					classes += ' ot_circularButton'
					break
			}
		}

		if (className) classes += ` ${className}`
		if (isConditional) classes += ' conditionalIcon'
		else {
			if (!label) classes += ' centered'
			else classes += ` icon-${iconPosition}`
		}

		if (alignText) classes += ` text-${alignText}`

		let styles = Object.assign({}, style, {
			minHeight: `${parseInt(size) + 6}px`,
		})

		let anex = React.Children.toArray(children)[1]

		return (
			<TagName
				className={`${classes}`}
				role='button'
				style={styles}
				onClick={disabled ? () => null : onClick}
				selected={selected}
				{...props}
				value={value}
			>
				{label && (
					<span className='label' style={{ color: `${color}`, float: 'right' }}>
						{label}
					</span>
				)}
				<div
					className={`svg-container ${isConditional ? `showIcon-${showIcon}` : ''}`}
					style={{ float: 'left' }}
				>
					{React.Children.map(children, (child, index) => {
						if (index === 0) {
							return React.cloneElement(child, {
								color: child.props.color ? child.props.color : color,
								direction,
								hovered,
								selected,
								size,
							})
						}
					})}
				</div>

				{anex && anex}
			</TagName>
		)
	}
}

export class IconPromoButton extends Button {
	static defaultClassName = 'ot_iconPromoButton'
	constructor(props) {
		super(props, IconPromoButton.defaultClassName)
	}
	render() {
		let {
			alignText,
			buttonType,
			bounded,
			children,
			className,
			color,
			disabled,
			direction,
			hovered,
			iconPosition = 'left',
			isConditional,
			label,
			onClick,
			selected,
			showIcon,
			size,
			style,
			tag,
			value,
			...props
		} = this.props
		let TagName = 'button'
		if (tag) TagName = tag.toLowerCase()

		let classes = 'btn ot_iconPromoButton'

		let styles = Object.assign({}, style, {
			minHeight: `${parseInt(size) + 6}px`,
			fontFamily: 'Inter', // Set the Inter font here
		})

		if (disabled) classes += ' disabled'
		else if (selected) classes += ' selected'
		else classes += ' unselected'

		if (!disabled && hovered) classes += ' hovered'
		if (bounded) classes = 'btn ot_iconPromoButton-bounded'
		if (buttonType) {
			switch (buttonType.toLowerCase()) {
				case 'default':
					classes += ' ot_defaultPromoButton'
					break
				case 'link':
					classes += ' ot_linkPromoButton'
					break
				case 'primary':
					classes += ' ot_primaryPromoButton'
					break
			}
		}

		if (className) classes += ` ${className}`
		if (isConditional) classes += ' conditionalIcon'
		else {
			if (!label) classes += ' centered'
			else classes += ` icon-${iconPosition}`
		}

		if (alignText) classes += ` text-${alignText}`

		//let styles = Object.assign( {}, style, { minHeight: `${parseInt( size ) + 6}px` } );

		let anex = React.Children.toArray(children)[1]

		return (
			<TagName
				className={`${classes}`}
				role='button'
				style={styles}
				onClick={disabled ? () => null : onClick}
				selected={selected}
				{...props}
				value={value}
			>
				{label && (
					<span className='label' style={{ color: `${color}`, float: 'right' }}>
						{label}
					</span>
				)}
				<div
					className={`svg-container ${isConditional ? `showIcon-${showIcon}` : ''}`}
					style={{ float: 'left' }}
				>
					{React.Children.map(children, (child, index) => {
						if (index === 0) {
							return React.cloneElement(child, {
								color: child.props.color ? child.props.color : color,
								direction,
								hovered,
								selected,
								size,
							})
						}
					})}
				</div>

				{anex && anex}
			</TagName>
		)
	}
}
//
export const ArrowButton = ({ ...props }) => (
	<IconButton {...props}>
		<ArrowIcon />
	</IconButton>
)

export const BackButton = ({ ...props }) => (
	<IconButton
		aria-label={'back button'}
		data-test='back_button'
		direction='left'
		iconPosition='left'
		style={props.label ? { transform: 'translateY(1px)' } : {}}
		{...props}
	>
		<ChevronIcon className='ot_backButton' />
	</IconButton>
)

export const CartButton = ({ className, cartSize, ...props }) => (
	<IconButton
		size='32'
		aria-label='cart button'
		data-test='cart_button'
		className={`ot_ci_cartButton ${className ? className : ''}`}
		{...props}
	>
		<CartIcon />
		<div className={`badge-container ${cartSize > 0 ? 'open' : 'close'}`}>
			{cartSize > 0 && <CountBadge className='ot_cartBadge' count={cartSize} />}
		</div>
	</IconButton>
)

export const ChevronButton = ({ ...props }) => (
	<IconButton {...props}>
		<ChevronIcon />
	</IconButton>
)

export const CheckedButton = ({ ...props }) => (
	<IconButton {...props}>
		<CheckIcon />
	</IconButton>
)

export const CircularButton = ({
	buttonType = 'circular',
	buttonColor = OtColors.gray,
	children,
	background = OtColors.gray_light,
	solid,
	style,
	...props
}) => {
	let circularStyle = {
		backgroundColor: solid ? background : OtColors.white,
		borderColor: solid ? props.color : 'transparent',
	}
	let buttonStyle = Object.assign(circularStyle, style)
	return (
		<IconButton style={buttonStyle} {...props}>
			{children}
		</IconButton>
	)
}

export const CloseButton = ({ ...props }) => (
	<IconButton {...props} aria-label='close button' data-test='close_button'>
		<CloseIcon />
	</IconButton>
)

export const PopUpCloseButton = ({ ...props }) => (
	<IconButton {...props} aria-label='close button' data-test='close_button'>
		<PopUpCloseIcon />
	</IconButton>
)

export const CountBadge = ({ className, count, ...props }) => (
	<div className={`ot_ci_countBadge ${className}`}>{count}</div>
)

export const DeleteButton = ({ ...props }) => (
	<IconButton {...props} aria-label='delete button' data-test='delete_button'>
		<TrashIcon />
	</IconButton>
)

export const DownButton = ({ ...props }) => (
	<IconButton
		aria-label='toggle button'
		data-test='toggle_button'
		direction='down'
		style={props.label ? { transform: 'translateY(1px)' } : {}}
		{...props}
	>
		<ChevronIcon className='ot_nextButton' />
	</IconButton>
)

export const EditButton = ({ ...props }) => (
	<IconButton aria-label='edit button' data-test='edit_button' {...props}>
		<PencilIcon />
	</IconButton>
)

export const FilterButton = ({ children, ...props }) => (
	<IconButton {...props} aria-label='filter button' data-test='filter_button'>
		<FilterIcon />
		{children}
	</IconButton>
)

export const HelpButton = ({ className, ...props }) => (
	<IconButton
		aria-label='help button'
		data-test='help_button'
		className={`ot_helpButton ${className ? className : ''}`}
		{...props}
	>
		<HelpIcon />
	</IconButton>
)

export const HomeButton = ({ ...props }) => (
	<IconButton
		{...props}
		className='ot_homeButton'
		aria-label='home button'
		data-test='home_button'
	>
		<HomeIcon />
	</IconButton>
)

export const LoginButton = ({ ...props }) => (
	<IconButton aria-label='login' data-test='login_button' {...props}>
		<LoginIcon />
	</IconButton>
)

export const LoginButtonMobile = ({ children, ...props }) => (
	<IconButton
		{...props}
		iconPosition='right'
		aria-label='login button'
		data-test='login_button'
	>
		{children}
		<LoginIconMobile />
	</IconButton>
)

export const MenuButton = ({ ...props }) => (
	<IconButton aria-label='menu button' data-test='menu_button' {...props}>
		<MenuIcon />
	</IconButton>
)

export const NextButton = ({ ...props }) => (
	<IconButton
		aria-label='next button'
		data-test='next_button'
		direction='right'
		iconPosition='right'
		style={props.label ? { transform: 'translateY(1px)' } : {}}
		{...props}
	>
		<ChevronIcon className='ot_nextButton' />
	</IconButton>
)

export const PrevButton = ({ ...props }) => (
	<IconButton
		aria-label='previous button'
		data-test='previous_button'
		direction='left'
		iconPosition='left'
		style={props.label ? { transform: 'translateY(1px)' } : {}}
		{...props}
	>
		<ChevronIcon className='ot_prevButton' />
	</IconButton>
)

// export const PromoButton = ({className, children, ...props}) => (
// 	<IconButton aria-label="promotion button" data-test="promotion_button" className={`ot_promoButton ${className ? className : ''}`} {...props}>
// 		<PromoIcon/>
// 		{children}
// 	</IconButton> );

export const TrashButton = ({ ...props }) => (
	<IconButton {...props} aria-label='delete button' data-test='delete_button'>
		<TrashIcon />
	</IconButton>
)

export const UserButton = ({ ...props }) => (
	<IconButton {...props} aria-label='user button' data-test='user_button'>
		<UserIcon />
	</IconButton>
)

export const ZoomInButton = ({ ...props }) => (
	<IconButton
		{...props}
		className='ot_zoomInButton'
		aria-label='zoomIn button'
		data-test='zoomIn_button'
	>
		<PlusIcon />
	</IconButton>
)

export const ZoomOutButton = ({ ...props }) => (
	<IconButton
		{...props}
		className='ot_zoomOutButton'
		aria-label='zoomOut button'
		data-test='zoomIn_button'
	>
		<MinusIcon />
	</IconButton>
)

export const CurveOutlineButton = ({ active, label, ...props }) => (
	<IconButton
		{...props}
		className={`ot_outlineButton ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<CurveOutlineIcon buttonType='default' />
		{label}
	</IconButton>
)

export const LineOutlineButton = ({ active, ...props }) => (
	<IconButton
		{...props}
		className={`ot_sectionButton  ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<LineOutlineIcon buttonType='default' />
	</IconButton>
)

export const AddOutlinePointButton = ({ active, ...props }) => (
	<IconButton
		{...props}
		className={`ot_sectionButton  ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<AddOutlinePointIcon buttonType='default' />
	</IconButton>
)

export const MoveOutlinePointButton = ({ active, ...props }) => (
	<IconButton
		{...props}
		className={`ot_sectionButton  ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<AddOutlinePointIcon buttonType='default' />
	</IconButton>
)

export const DeleteOutlinePointButton = ({ active, ...props }) => (
	<IconButton
		{...props}
		className={`ot_sectionButton  ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<DeleteOutlinePointIcon buttonType='default' />
	</IconButton>
)

export const ResetOutlineButton = ({ active, ...props }) => (
	<IconButton
		{...props}
		className={`ot_sectionButton  ${active ? 'active' : ''}`}
		aria-label='curve outline button'
		data-test='curve_outline_button'
	>
		<MoveIcon buttonType='default' active='true' />{' '}
		{/*TODO get the correct icon from PJ*/}
	</IconButton>
)

/*export const ApplyCodeButton = ({...props}) => <IconButton {...props}><ApplyIcon/></IconButton>;*/
// eslint-disable-next-line no-unused-vars
export const ApplyCodeButton = ({ isCodeCorrect, text, color, ...props }) => {
	const { onRemoveCode, ...otherProps } = props

	return (
		<IconPromoButton {...otherProps}>
			{isCodeCorrect ? (
				<TrashIcon color={color} onClick={onRemoveCode} />
			) : (
				<TextIcon text='Apply' onClick={props.onClick} />
			)}
		</IconPromoButton>
	)
}
