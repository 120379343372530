import React from 'react';
import PropTypes from 'prop-types';
import { detectIE } from 'util/DetectBrowser';
import Flex from 'components/common/containers/Flex';
import 'sass/components/utils/_LoadingView.scss';

const LoadingIcon = ({ size = "sm" }) => {
    const svgIcon = (
        <svg className={`ot_loadingIcon_${size || "sm"}`} x="0px" y="0px" viewBox="0 0 200 375" style={{enableBackground: "new 0 0 200 375"}}>
        <g className="canvas">
          <g className="image">
            <path className="st0" strokeLinecap="round" d="M90,75 Q 145 75, 180 100 L 180 300 Q 145 325, 90 325" />
            <path className="st0" strokeLinecap="round"  d="M90,325 Q 30 325, 0 300 L 0 100 Q 30 75, 90 75"/>
            <path className="outer st1" strokeLinecap="round"  d="M90,75 Q 145 75, 180 100 L 180 300 Q 145 325, 90 325"/>
            <path className="outer st1" strokeLinecap="round"  d="M90,75 Q 30 75, 0 100 L 0 300 Q 30 325, 90 325"/>
            <g>
                <path className="st0" strokeLinecap="round"  d="M90,140 q 10 0 20 -10 a 20 20, 0,0,1, 20,20 L 130 250 A 20 20, 0, 0, 1, 110 270 Q 100 260 90 260"/>
                <path className="st0" strokeLinecap="round"  d="M90,140 q -10 0 -20 -10 a 20 20, 0,0,0, -20,20 L 50 250 A 20 20, 0, 0, 0, 70 270 Q 80 260 90 260"/>
                <path className="inner st1" strokeLinecap="round"  d="M90,140 q 10 0 20 -10 a 20 20, 0,0,1, 20,20 L 130 250 A 20 20, 0, 0, 1, 110 270 Q 100 260 90 260"/>
                <path className="inner st1" strokeLinecap="round"  d="M90,140 q -10 0 -20 -10 a 20 20, 0,0,0, -20,20 L 50 250 A 20 20, 0, 0, 0, 70 270 Q 80 260 90 260"/>
            </g>
          </g>
        </g>
      </svg>
    );

    return detectIE ? <i className="ot_loadingGif" /> : svgIcon;
};

LoadingIcon.propTypes = {
    size: PropTypes.string,
};

const LoadingView = ({ className, size, text, ...rest }) => (
    <Flex className={`ot_ci_loadingSpinner ${className}`} style={{ marginTop: '40px', marginBottom: '40px' }} {...rest}>
        <LoadingIcon size={size} />
        {text && <p className="pulse-in-out" style={{ marginLeft: '30px', marginRight: '30px' }}>{text}</p>}
    </Flex>
);

LoadingView.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    text: PropTypes.string,
};

const LoadingOverlay = ({ minHeight, children }) => {
    const defaultContent = [<LoadingIcon key="loading-icon" />];
    const style = minHeight ? { minHeight } : {};

    return (
        <Flex className="ot_ci_loadingOverlay" style={style}>
            {children || defaultContent}
        </Flex>
    );
};

LoadingOverlay.propTypes = {
    minHeight: PropTypes.string,
    children: PropTypes.node,
};

export { LoadingIcon, LoadingView, LoadingOverlay };