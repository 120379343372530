import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CaretIcon } from 'components/common/icons/SvgIcons'
import { colors, theme } from 'theme'
import { motion, AnimatePresence } from 'framer-motion'
import TicketAccordionButtons from './TicketAccordionButtons'
import TicketType from '../TicketType'
import { TicketContext, types } from '../ticketContext/TicketContext'

const AccordionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${theme.padding.mdlg} ${theme.padding.lg};
	background-color: ${colors.accordions.background};
	border-top: 1px solid ${colors.borders.black};
	border-radius: 0 0 ${theme.borderRadius.md} ${theme.borderRadius.md};

	&:hover {
		background-color: ${(props) =>
			props.active ? colors.surfaces.alternate : 'auto'};
	}
`

const CaretContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const AccordionTitle = styled.p`
	font-size: ${theme.fontSizes.sm};
	font-weight: ${theme.fontWeight.semiBold};
`

const AccordionCollapsed = styled.div`
	border-top: 1px solid ${colors.borders.black};
	overflow: hidden;
`

const NoPromoTickets = styled.p`
	display: flex;
	justify-content: center;
	padding: ${theme.padding.lg};

	font-weight: ${theme.fontWeight.semiBold};
	font-size: ${theme.fontSizes.md};
`

const TicketAccordion = ({
	isActive,
	ticket,
	isFirstAccordion,
	handleSetActiveAccordion,
}) => {
	const ticketContext = useContext(TicketContext)

	useEffect(() => {
		if (!ticketContext.collapsedAccordionOnce && isFirstAccordion) {
			ticketContext.setCollapsedAccordionOnce(true)
			handleSetActiveAccordion(ticket)
		}
	}, [])

	const toggleAccordion = () => {
		handleSetActiveAccordion(ticket)
	}

	return (
		<motion.div
			key={ticket.ticketTypeId}
			initial={{ opacity: 0, height: 0 }}
			animate={{ opacity: 1, height: 'auto' }}
			exit={{ opacity: 0, height: 0 }}
			transition={{ duration: 0.3 }}
		>
			<AccordionContainer
				style={{ cursor: !isFirstAccordion && !isActive ? 'pointer' : 'auto' }}
				onClick={!isFirstAccordion && !isActive ? toggleAccordion : null}
			>
				<AccordionTitle>{ticket.name}</AccordionTitle>
				{(isActive || isFirstAccordion) && (
					<CaretContainer
						onClick={isFirstAccordion || isActive ? toggleAccordion : null}
					>
						<CaretIcon cursor='pointer' direction={isActive ? 'down' : 'up'} />
					</CaretContainer>
				)}
			</AccordionContainer>
			<AnimatePresence initial={false}>
				{isActive && (
					<AccordionCollapsed
						as={motion.div}
						initial={{ height: 0 }}
						animate={{ height: 'auto' }}
						exit={{ height: 0 }}
						transition={{ duration: 0.3 }}
					>
						<TicketAccordionButtons />
						{ticketContext.selectedTicketType === types.promo ? (
							!ticket?.promotion?.length ? (
								<NoPromoTickets>There are no promo tickets for this seat</NoPromoTickets>
							) : (
								ticket?.promotion?.map((t, index) => (
									<TicketType
										isPromo={true}
										title={t.name}
										key={index}
										ticket={t}
									/>
								))
							)
						) : (
							<TicketType ticket={ticket} />
						)}
					</AccordionCollapsed>
				)}
			</AnimatePresence>
		</motion.div>
	)
}

export default TicketAccordion
