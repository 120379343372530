import {VectorUtils} from "./VectorUtils"
export class GraphicsUtils {
  static degreesToRadians(deg) {
    return deg*Math.PI/180;
  }
  static rectangleFrom2Points(pt1,pt2) {
    if(!pt2) {
      pt2=pt1;
      pt1=[0,0];
    }
    let maxPoint=VectorUtils.max(pt1,pt2);
    let minPoint=VectorUtils.min(pt1,pt2);
    let [width,height]=VectorUtils.subtract(maxPoint,minPoint)
    return {
      x:minPoint[0],
      y:minPoint[1],
      width,
      height,
      center:VectorUtils.average(minPoint,maxPoint)
    }
  }
  static polygonFromRectangle(rect) {
    //[topLeft,topRight,bottomRight,bottomLeft]
    return [
        [rect.x,rect.y],
        [rect.x+rect.width,rect.y],
        [rect.x+rect.width,rect.y+rect.height],
        [rect.x,rect.y+rect.height]
    ]
  }
  static pointsFromRectangle(rect) {
    let [topLeft,topRight,bottomRight,bottomLeft] =GraphicsUtils.polygonFromRectangle(rect)
    return {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      center: VectorUtils.average(topLeft,topRight,bottomLeft,bottomRight)
    }
  }
  static rotatePolygon(points,angle,origin=[0,0]) {
    return points.map((point)=>GraphicsUtils.rotatePoint2D(point,angle,origin))
  }
  static rotateRectangle(rect,angle,origin=[0,0]) {
    if(!rect.topLeft) {
      rect=GraphicsUtils.pointsFromRectangle(rect);
    }
    let newRect={
      topLeft: GraphicsUtils.rotatePoint2D(rect.topLeft, angle, origin),
      topRight: GraphicsUtils.rotatePoint2D(rect.topRight, angle, origin),
      bottomLeft: GraphicsUtils.rotatePoint2D(rect.bottomLeft, angle, origin),
      bottomRight: GraphicsUtils.rotatePoint2D(rect.bottomRight, angle, origin)
    }
    let maxPoint=VectorUtils.max(newRect.topLeft,newRect.topRight,newRect.bottomLeft,newRect.bottomRight)
    let minPoint=VectorUtils.min(newRect.topLeft,newRect.topRight,newRect.bottomLeft,newRect.bottomRight)
    return GraphicsUtils.rectangleFrom2Points(maxPoint,minPoint);
  }
  static rotatePoint2D(point,theta,origin=[0,0]) {
    if(!Array.isArray(point) || point.length!==2) {
      throw new Error("Point must be 2d point")
    }
    let cartesianRelativePoint=VectorUtils.subtract(point,origin);
    let angle=GraphicsUtils.degreesToRadians(theta);
    return [
      origin[0]+cartesianRelativePoint[0]*Math.cos(angle)-cartesianRelativePoint[1]*Math.sin(angle),
      origin[1]+cartesianRelativePoint[0]*Math.sin(angle)+cartesianRelativePoint[1]*Math.cos(angle)
    ]
  }
}
