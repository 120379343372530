import {
  EditorEvents,
  ClientSeatingChartEvents,
  ClientTicketSelectionEvents,
} from "actions/Events";


export const loadSeatingChart = (state, props) => state
  .seatingChartReducer
  .seatingCharts
  .loadSeatingChartById(props.params.seatingChartId);

export const removeObject = (object) => {
  if (object.id > 0) {
    return {
      type: EditorEvents.REMOVE_OBJECT,
      object
    };
  }
  else {
    return {
      type: EditorEvents.REMOVE_NEW_OBJECT,
      newObject: object
    };
  }
};

export const selectObjectResize = (point) => {
  return {
    type: EditorEvents.SELECT_RESIZE_POINT,
    point
  };
};

export const changeTool = (tool, anchor) => {
  return {
    type: EditorEvents.CHANGE_TOOL,
    tool,
    anchor
  };
};
  
export const selectObject = (selectedObject, tool, anchor) => {
  return {
    type: EditorEvents.SELECT_OBJECT,
    selectedObject,
    tool,
    anchor
  };
};

export const createObject = (newObjects, tool, anchor) => {
  return {
    type: EditorEvents.NEW_OBJECT,
    newObjects,
    tool,
    anchor
  };
};

export const resetEditor = () => {
  return {
    type: EditorEvents.RESET_EDITOR,
  }
}

export const updateView = ({ panDx, panDy }) => {
  return {
    type: EditorEvents.UPDATE_VIEW,
    panDx,
    panDy,
  };
}

export const zoomScale = (current) => {
  return {
    type: EditorEvents.UPDATE_SCALE,
    current
  };
}

export const updateViewPort = (newViewPort, homeScale) => {
	return {
    type: EditorEvents.UPDATE_VIEWPORT,
    newViewPort,
    homeScale
  };
}

export const resetPan = () => {
  return {
      type: EditorEvents.RESET_PAN
    };
}

export const initView = (view) => {
  return {
    type: EditorEvents.INIT_VIEW,
    view
  };
}

export const saveChart = () => {
  return {
    type: EditorEvents.SAVE_CHART,
  };
}

export const startProcessing = () => {
  return {
    type: EditorEvents.PROCESSING_STARTED,
  };
}

export const completedProcessing = () => {
  return {
    type: EditorEvents.PROCESSING_COMPLETE,
  };
}

export const updateUndoSectionHistory = (object, history) => {
  return {
    type: EditorEvents.UPDATE_UNDO_SECTION_HISTORY,
    object,
    history
  };
}

export const updateRedoSectionHistory = (object, history) => {
  return {
    type: EditorEvents.UPDATE_REDO_SECTION_HISTORY,
    object,
    history
  };
}

export const dragSeatingChart = (dX, dY) => {
  return {
    type: ClientSeatingChartEvents.DRAG_SEATING_CHART,
    dX,
    dY
  };
}

export const resetDragSeatingChart = () => {
  return {
    type: ClientSeatingChartEvents.RESET_DRAG_SEATING_CHART,
  };
}

export const selectTemporarySeat = (seat) => {
  return {
    type: ClientTicketSelectionEvents.SELECT_TEMPORARY_SEAT,
    seat,
  };
}

export const addSelectedSeat = (selectedSeats) => {
  return {
    type: ClientTicketSelectionEvents.ADD_SELECTED_SEATS,
    selectedSeats,
  };
}