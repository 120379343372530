import { diff } from 'deep-object-diff'
import React from 'react'
import { connect } from 'react-redux'
import { Section } from 'components/seatingChart/seatSelection/Section'
import { VectorUtils } from 'util/MathFunctions'
import { MouseDragHandler } from 'components/MouseDragHandler'
import { MapToolbar } from 'components/seatingChart/seatSelection/MapToolbar'
import { SeatingChartObject } from 'components/seatingChart/shared/objects/SeatingChartObject'
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom'
import './../../../sass/components/seatingChart/SeatingChart.scss'
import { MapControllers } from 'components/seatingChart/seatSelection/MapControllers'
// selectors
import { getClientViewportAttributes } from 'components/seatingChart/selectors'

// actions
import {
	zoomScale,
	initView,
	dragSeatingChart,
	resetDragSeatingChart,
} from 'components/seatingChart/actions'

class SeatingChart extends React.Component {
	chart = React.createRef()
	viewport = React.createRef()
	seatingChart = React.createRef()
	Viewer = null
	state = {
		dragging: false,
		dragState: [],
		priceLevelFilters: [],
		priceRangeFilterApplied: false,
		showPriceLevels: !this.props.stepSelect,
		ticketTypeData: {},
		chartBox: {
			x: 0,
			y: 0,
			width: 0,
			height: 0,
		},
		mobileScale: {
			min: this.props.scale.min,
			max: this.props.scale.max,
			home: this.props.scale.home,
			current: this.props.scale.home,
		},
		mobileCurrentScale: 0,
	}

	componentDidMount() {
		const {
			chart,
			chartSize,
			viewPort,
			viewPortChartCenter,
			scale,
			pan,
			onInitView,
			isSmallDevice,
		} = this.props

		if (isSmallDevice) {
			this.Viewer.fitToViewer()
			this.setState({
				mobileScale: {
					...this.state.mobileScale,
					current: this.state.mobileScale.home,
				},
			})
		}

		onInitView({ chart, chartSize, scale, pan, viewPort, viewPortChartCenter })

		const viewport = this.getViewport()
		if (viewport) {
			viewport.addEventListener('wheel', this.mouseWheelZoom, {
				passive: false,
			})
		}
	}

	componentWillUnmount() {
		const { scale, chart, viewPort, onInitView } = this.props
		this.props.onResetDragSeatingChart()
		viewPort.centerToScale(scale.home, chart.centerPoint)
		onInitView({
			viewPort,
			viewPortChartCenter: chart.centerPoint,
			scale: {
				...scale,
				current: scale.home,
			},
		})

		const viewport = this.getViewport()
		if (viewport) {
			viewport.removeEventListener('wheel', this.mouseWheelZoom)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.chart.current) {
			const chartBox = this.chart.current.getBBox()
			if (
				prevState.chartBox.x !== chartBox.x ||
				prevState.chartBox.y !== chartBox.y ||
				prevState.chartBox.width !== chartBox.width ||
				prevState.chartBox.height !== chartBox.height
			) {
				this.setState({
					chartBox: {
						width: chartBox.width,
						height: chartBox.height,
						x: chartBox.x,
						y: chartBox.y,
					},
				})
			}
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const stateDiff = diff(nextState, this.state)
		if (Object.keys(stateDiff).length !== 0) {
			return true
		}
		return Object.keys(diff(nextProps, this.props)).length !== 0
	}

	getViewport = () => this.viewport.current

	// Event handlers
	onDragEnd = () => {
		this.setState({ dragging: false })
		this.props.isDragging(false)
	}

	onDragStart = () => {
		this.setState({ dragging: true })
		this.props.isDragging(true)
	}

	onDrag = (dragState) => {
		const { dX, dY } = dragState
		this.props.onDragSeatingChart(dX, dY)
		this.props.isDragging(true, dX, dY)
		const viewPortChartCenter = VectorUtils.subtract(
			this.props.viewPortChartCenter,
			VectorUtils.toVector(dX, dY)
		)

		this.props.viewPort.centerToScale(
			this.props.scale.current,
			viewPortChartCenter
		)
		this.props.onInitView({
			viewPort: this.props.viewPort,
			viewPortChartCenter,
		})
		this.setState({ dragState })
	}

	onClick = () => {
		if (!this.props.isFixedPackageSeating) {
			this.props.hidePopup()
		}
	}

	pinchZoom = (dragState) => {
		const { scale } = this.props
		const newScale = Math.max(
			scale.min,
			Math.min(scale.max, scale.current * dragState.scale)
		)
		const viewPort = this.props.viewPort.centerToScale(
			newScale,
			dragState.currentRectangle.center
		)

		this.setState({
			scale: {
				...this.state.scale,
				current: newScale,
			},
			viewPort,
			viewPortChartCenterPoint: null, // Define o actualiza esto si es necesario
			animate: true,
		})
	}

	dragHandler = new MouseDragHandler()
		.onDrag(this.onDrag)
		.onDragEnd(this.onDragEnd)
		.onDragStart(this.onDragStart)
		.onClick(this.onClick)
		.onPinch(this.pinchZoom)

	// Zoom methods
	zoomHome = () => {
		const { scale, chart, viewPort, onInitView } =
			this.props
			!!this.Viewer && this.Viewer.fitToViewer()
			this.setState({
				mobileScale: {
					...this.state.mobileScale,
					current: this.state.mobileScale.home,
				},
			})
			this.props.onResetDragSeatingChart()
			viewPort.centerToScale(scale.home, chart.centerPoint)
			onInitView({
				viewPort,
				viewPortChartCenter: chart.centerPoint,
				scale: {
					...scale,
					current: scale.home,
				},
			})
		// if (isSmallDevice) {
		// 	this.Viewer.fitToViewer()
		// 	this.setState({
		// 		mobileScale: {
		// 			...this.state.mobileScale,
		// 			current: this.state.mobileScale.home,
		// 		},
		// 	})
		// } else {
		// 	this.props.onResetDragSeatingChart()
		// 	viewPort.centerToScale(scale.home, chart.centerPoint)
		// 	onInitView({
		// 		viewPort,
		// 		viewPortChartCenter: chart.centerPoint,
		// 		scale: {
		// 			...scale,
		// 			current: scale.home,
		// 		},
		// 	})
		// 	if (!this.props.isFixedPackageSeating) {
		// 		hidePopup()
		// 	}
		// }
	}

	zoomIn = (center, clickToShowSeats = false) => {
		const {
			scale,
			viewPort,
			viewPortChartCenter,
			onInitView,
			hidePopup,
		} = this.props
		const scaleStep = 0.05
		let newScale = 0
		if (clickToShowSeats) {
			newScale = Math.min(scale.home + scaleStep, scale.max)
		} else {
			newScale = Math.min(scale.current + scaleStep, scale.max)
		}

		const centerPoint = Array.isArray(center) ? center : viewPortChartCenter
		if (Array.isArray(center)) {
			const dX = center[0] - viewPortChartCenter[0]
			const dY = center[1] - viewPortChartCenter[1]
			this.props.onDragSeatingChart(-dX, -dY)
		}

		viewPort.centerToScale(newScale, centerPoint)
		onInitView({
			viewPort,
			viewPortChartCenter: centerPoint,
			scale: {
				...scale,
				current: newScale,
			},
		})
		if (!this.props.isFixedPackageSeating) {
			hidePopup()
		}
		// if (isSmallDevice) {
		// 	const scaleStep = 0.05
		// 	this.Viewer.zoomOnViewerCenter(1 + scaleStep)
		// } else {
		// 	const scaleStep = isNaN(center) ? DEFAULT_SCALE_STEP : center
		// 	let newScale = 0
		// 	if (clickToShowSeats) {
		// 		newScale = Math.min(scale.home + scaleStep, scale.max)
		// 	} else {
		// 		newScale = Math.min(scale.current + scaleStep, scale.max)
		// 	}

		// 	const centerPoint = Array.isArray(center) ? center : viewPortChartCenter
		// 	if (Array.isArray(center)) {
		// 		const dX = center[0] - viewPortChartCenter[0]
		// 		const dY = center[1] - viewPortChartCenter[1]
		// 		this.props.onDragSeatingChart(-dX, -dY)
		// 	}

		// 	viewPort.centerToScale(newScale, centerPoint)
		// 	onInitView({
		// 		viewPort,
		// 		viewPortChartCenter: centerPoint,
		// 		scale: {
		// 			...scale,
		// 			current: newScale,
		// 		},
		// 	})
		// 	if (!this.props.isFixedPackageSeating) {
		// 		hidePopup()
		// 	}
		// }
	}

	zoomOut = (center) => {
		const {
			scale,
			viewPort,
			viewPortChartCenter,
			onInitView,
			hidePopup,
		} = this.props
		const scaleStep = 0.05
		//const scaleStep = isNaN(center) ? DEFAULT_SCALE_STEP : center
		const newScale = Math.max(scale.current - scaleStep, scale.min)
		const centerPoint = Array.isArray(center) ? center : viewPortChartCenter

		viewPort.centerToScale(newScale, centerPoint)
		onInitView({
			viewPort,
			viewPortChartCenter: centerPoint,
			scale: {
				...scale,
				current: newScale,
			},
		})
		if (!this.props.isFixedPackageSeating) {
			hidePopup()
		}
		// if (isSmallDevice) {
		// 	const scaleStep = 0.05
		// 	this.Viewer.zoomOnViewerCenter(1 / (1 + scaleStep))
		// } else {
		// 	const scaleStep = isNaN(center) ? DEFAULT_SCALE_STEP : center
		// 	const newScale = Math.max(scale.current - scaleStep, scale.min)
		// 	const centerPoint = Array.isArray(center) ? center : viewPortChartCenter

		// 	viewPort.centerToScale(newScale, centerPoint)
		// 	onInitView({
		// 		viewPort,
		// 		viewPortChartCenter: centerPoint,
		// 		scale: {
		// 			...scale,
		// 			current: newScale,
		// 		},
		// 	})
		// 	if (!this.props.isFixedPackageSeating) {
		// 		hidePopup()
		// 	}
		// }
	}

	mouseWheelZoom = (evt) => {
		const { deltaY } = evt
		if (evt.ctrlKey === true) {
			evt.preventDefault()
		}

		if (deltaY < 0) {
			this.zoomIn(0.1 * (Math.abs(deltaY) / 100))
		} else {
			this.zoomOut(0.1 * (deltaY / 100))
		}
	}

	getActivePriceLevels = () => {
		const { priceLevels } = this.props.data

		const sections = this.getRenderableSections()
		if (!sections) {
			return priceLevels
		}

		// filter all the price levels for the available sections
		let activePriceLevelIds = []
		for (let sec of sections) {
			if (sec.availableSeats > 0) {
				activePriceLevelIds = activePriceLevelIds.concat(sec.priceLevelIds)
			}
		}

		return Object.values(priceLevels).filter((pl) =>
			activePriceLevelIds.includes(pl.id)
		)
	}

	getItems = (activePriceLevels) => {
		return Object.values(activePriceLevels)
			.map((pl) => {
				const tts = pl.ticketTypes.map((tt) => tt.price)
				const max = Math.ceil(Math.max(...tts))
				const min = Math.floor(Math.min(...tts))
				return { min, max, priceLevel: pl }
			})
			.sort(
				(pl1, pl2) => pl1.priceLevel.displayOrder - pl2.priceLevel.displayOrder
			)
	}

	// Rendering methods
	renderSection = (section, index) => {
		const showOutline = this.props.isSmallDevice
			? this.state.mobileScale.home >= this.state.mobileScale.current
			: this.props.scale.current === this.props.scale.home ||
				this.props.scale.current < this.props.scale.home

		const position = Section.getSectionPosition(section)

		const filtersLength =
			this.props.onlySeatSelection && this.state.selectedSeats
				? Object.keys(this.state.selectedSeats).length ===
					this.props.seatsToSelect
				: this.state.filtersLength

		const isTraditionalSeatingChart = this.props.data.traditional
		const selectedSeats = Object.assign({}, this.props.selectedSeats)

		return (
			<g transform={`translate(${position[0]} ${position[1]})`} key={index}>
				<Section
					showOutline={showOutline && isTraditionalSeatingChart}
					priceLevelFilters={this.state.priceLevelFilters}
					priceRangeFilterApplied={this.state.priceRangeFilterApplied}
					selectedSeats={selectedSeats}
					seatsToSelect={this.props.seatsToSelect}
					onlySeatSelection={this.props.onlySeatSelection}
					filtersLength={filtersLength}
					showSeatNumber={false}
					isFixedPackageSeating={this.props.isFixedPackageSeating}
					disabledSeatsForFixedPlusPackage={
						this.props.disabledSeatsForFixedPlusPackage
					}
					onSectionClick={!this.props.legacy && this.handleSectionClick}
					data={section}
					rotate={section.rotation}
					skewX={section.skewX}
					skewY={section.skewY}
					curve={section.curve}
					seatAlign={section.seatAlign}
					onSeatSelect={this.props.onSeatSelect}
					onSeatUnselect={this.props.onSeatUnselect}
					isSmallDevice={this.props.isSmallDevice}
					isMobile={this.props.isMobile}
				/>
			</g>
		)
	}

	createSelectionObject() {
		let {
			ticketTypeData: {
				selectedTicketTypes = {},
				order,
				orderTotal,
				orderTickets,
			},
		} = this.state
		let { selectedSeats } = this.state

		let selectedSeatsLength = Object.keys(selectedSeats).length,
			selectedTicketTypesLength = Object.keys(selectedTicketTypes).length

		let ttCopy = Object.assign({}, selectedTicketTypes)
		Object.keys(selectedSeats).forEach((key) => delete ttCopy[key])

		let isValid =
			selectedSeatsLength === selectedTicketTypesLength &&
			Object.keys(ttCopy).length === 0

		return {
			selectedSeatsLength,
			selectedSeats,
			selectedTicketTypesLength,
			selectedTicketTypes,
			order,
			orderTotal,
			orderTickets,
			isValid,
		}
	}

	handleFilterChange = (priceLevels) => {
		this.setState({
			priceLevelFilters: priceLevels,
			priceRangeFilterApplied: true,
			filtersLength:Object.keys(priceLevels).length
		});
	}
	onSeatSelect = (data) => {
		if (data.inCart && typeof this.props.onSelectCartSeat === 'function') {
			this.props.onSelectCartSeat(data.id)
		} else {
			let selectedSeats = Object.assign({}, this.props.selectedSeats, {
				[data.id]: data,
			})
			if (typeof this.props.onSeatSelectionChange === 'function') {
				const request = this.createSelectionObject()
				const selectedSeatsLength = Object.keys(selectedSeats).length
				Object.assign(request, { selectedSeats, selectedSeatsLength })
				this.props.onSeatSelectionChange(request)
			}
		}
	}
	onSeatUnselect = (data) => {
		const selectedSeats = Object.assign({}, this.props.selectedSeats)
		delete selectedSeats[data.id]

		const showPriceLevels =
			this.state.showPriceLevels && Object.keys(selectedSeats).length > 0

		this.setState(
			{
				// selectedSeats,
				showPriceLevels,
			},
			() => {
				if (typeof this.props.onSeatSelectionChange === 'function') {
					const request = this.createSelectionObject()
					Object.assign(request, { selectedSeats })
					this.props.onSeatSelectionChange(request)
				}
				if (
					!this.state.onlySeatSelection &&
					!showPriceLevels &&
					typeof this.props.onStepRegress === 'function'
				) {
					this.props.onStepRegress()
				}
			}
		)
	}

	renderObject = (obj, index) => (
		<SeatingChartObject
			key={index}
			data={obj}
			onClickLabel={this.handleLabelClick}
		/>
	)

	renderObjects = () => {
		const objects = this.props.data.objects || []
		const sections = this.getRenderableSections().map((section) => section)
		const items = sections
			.concat(objects)
			.sort((obj1, obj2) => obj1.layer - obj2.layer)
		return items.map((obj, index) =>
			obj.type === 'SECTION' &&
			obj.sectionType === 'C' &&
			obj.priceLevelIds.length > 0
				? this.renderSection(obj, index)
				: this.renderObject(obj, index)
		)
	}

	getRenderableSections = (sect) => {
		if (sect) {
			return sect
		}
		const {
			data: { sections },
		} = this.props
		return sections
	}

	render() {
		let {
			className,
			noToolBar,
			isFixedPackageSeating,
			scale,
			viewPort,
			viewPortSize,
			pan
		} = this.props
		let { animate, dragging } = this.state

		const origin = VectorUtils.scalarMultiply(viewPortSize, 0.5)
		const canMove = viewPort ? viewPort.canMove : false
		const [translateX, translateY] = viewPort ? viewPort.location : [0, 0]
		const sections = this.getRenderableSections().map((section) => section);

		const activePriceLevels = this.getActivePriceLevels()
		const priceLevels = activePriceLevels
			? this.getItems(activePriceLevels)
			: undefined

		const miniatureProps = { position: 'none' }

		const hasPan = this.props.pan && (this.props.pan.dX !== 0 || this.props.pan.dY !== 0 ) ;

		const onZoom = (event) => {
			this.setState({
				mobileScale: {
					...scale,
					current: event.a,
				},
			})
		}

		const isTraditionalSeatingChart = this.props.data.traditional

		return (
			<div
				className={`ot_seatingChart ${className ? className : ''}`}
				style={this.props.style} {...this.dragHandler.mouseEvents}
			>
			<MapToolbar
				quantity={this.props.quantity}
				showOutline={this.state.mobileScale.home >= this.state.mobileScale.current && isTraditionalSeatingChart}
				disableSelect={this.props.disableFilter}
				hasAccSeating={this.props.hasAccSeating}
				onFilterChange={this.handleFilterChange}
				priceLevels={priceLevels}
				priceLevelFilters={this.state.priceLevelFilters}
				noToolBar={noToolBar}
				isSmallDevice={this.props.isSmallDevice}
				isFixedPackageSeating={isFixedPackageSeating}
				currencySymbol={this.props.currencySymbol}
				featureFlags={this.props.featureFlags}
			/>
			<MapControllers
				quantity={this.props.quantity}
				scale={scale}
				hasPan={hasPan}
				pan={pan}
				sections={sections}
				viewPort={viewPort}
				viewPortChartCenter={this.props.viewPortChartCenter}
				dragging={this.state.dragging}
				seatingChart={this.props.seatingChart}
				viewPortSize={viewPortSize}
				chartSize={this.props.chartSize}
				mobileScale={this.state.mobileScale}
				noToolBar={noToolBar}
				isFixedPackageSeating={isFixedPackageSeating}
				zoom={{
					onHome: this.zoomHome,
					onZoomIn: this.zoomIn,
					onZoomOut: this.zoomOut
				}}
			/>

				{this.props.isSmallDevice ? (
					<UncontrolledReactSVGPanZoom
						width={viewPortSize[0]}
						height={viewPortSize[1] - 139 - 52}
						background='#ffffff'
						ref={(Viewer) => (this.Viewer = Viewer)}
						scaleFactorMin={scale.min}
						scaleFactorMax={scale.max}
						detectAutoPan={false}
						detectWheel={false}
						miniatureProps={miniatureProps}
						toolbarProps={miniatureProps}
						style={{ marginTop: 52 }}
						tool='auto'
						onZoom={onZoom}
					>
						<svg
							viewBox={`${this.props.chart.minPoint[0]} ${this.props.chart.minPoint[1]} ${this.props.chartSize[0]} ${this.props.chartSize[1]}`}
							ref={this.viewport}
							id='viewport'
							className='chart_svg'
						>
							{viewPort && (
								<g id='viewportGroup' className={`${animate ? 'animate' : ''}`}>
									<g ref={this.chart}>
										<g className={`chart_group ${animate ? 'animate' : ''}`}>
											{this.renderObjects()}
										</g>
									</g>
								</g>
							)}
						</svg>
					</UncontrolledReactSVGPanZoom>
				) : (
					<svg
						ref={this.viewport}
						id='viewport'
						width={viewPortSize[0]}
						height={viewPortSize[1]}
						viewBox={`0 0 ${viewPortSize[0]} ${viewPortSize[1]}`}
						className={`chart_svg ${canMove ? 'canMove' : ''} ${
							dragging ? 'dragging' : ''
						}`}
						onWheel={this.mouseWheelZoom}
						{...this.dragHandler.mouseEvents}
					>
						{viewPort && (
							<g
								id='viewportGroup'
								transform={`translate(${origin[0] || 0}, ${origin[1] || 0})`}
								className={`${animate ? 'animate' : ''}`}
							>
								<g
									ref={this.chart}
									transform={`translate(${translateX || 0}, ${
										translateY || 0
									})`}
								>
									<g
										transform={`scale(${scale.current})`}
										className={`chart_group ${animate ? 'animate' : ''}`}
									>
										{this.renderObjects()}
									</g>
								</g>
							</g>
						)}
					</svg>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	const seatingChart = ownProps.data
	const viewportAttributes = seatingChart
		? getClientViewportAttributes(state, ownProps)
		: {}

	return {
		...ownProps,
		...viewportAttributes,
	}
}

const mapDispatchToProps = (dispatch) => ({
	onInitView: (view) => dispatch(initView(view)),
	onZoomScale: (scale) => dispatch(zoomScale(scale)),
	onDragSeatingChart: (dX, dY) => {dispatch(dragSeatingChart(dX, dY))},
	onResetDragSeatingChart: () => dispatch(resetDragSeatingChart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SeatingChart)
