import React from "react";
import { EDIT_NUB_SIZE } from "components/seatingChart/shared/SeatingChartConfig";
import { CircleObject } from "components/seatingChart/shared/objects/CircleObject";
import { RectangleObject } from "components/seatingChart/shared/objects/RectangleObject";
import { CurveObject } from "components/seatingChart/shared/objects/CurveObject";
import { LabelObject } from "components/seatingChart/shared/objects/LabelObject";
import { LineObject } from "components/seatingChart/shared/objects/LineObject";
import { ArrowObject } from "components/seatingChart/shared/objects/ArrowObject";
import { CurvePolygonObject } from "components/seatingChart/shared/objects/CurvePolygonObject";
import {OtColors} from 'util/OtColors';
import { EditorSection } from "components/seatingChart/editor/EditorSection";

const OBJECTS = {
  ARROW: ArrowObject,
  CIRCLE: CircleObject,
  RECTANGLE: RectangleObject,
  LINE: LineObject,
  CURVE: CurveObject,
  CURVE_POLYGON: CurvePolygonObject ,
  LABEL: LabelObject
};

const ALLOWABLE_OBJECTS = [
  'CIRCLE',
  'RECTANGLE',
  'LABEL',
  'CURVE',
  'CURVE_POLYGON'
];

export class SeatingChartObject extends React.Component
{
  static getAbsoluteBoundingRect(object, positions) {
    switch(object.type) {
      // case "ARROW":
        // not implemented
      case "RECTANGLE":
        return RectangleObject.getAbsoluteBoundingRect(object);
      case "CIRCLE":
        return CircleObject.getAbsoluteBoundingRect(object);
      case "LINE":
        return LineObject.getAbsoluteBoundingRect(object);
      case "CURVE":
        return CurveObject.getAbsoluteBoundingRect(object);
      case "CURVE_POLYGON":
        return CurvePolygonObject.getAbsoluteBoundingRect(object, positions);
      case "LABEL":
        return LabelObject.getAbsoluteBoundingRect(object);
      default:
        return null;
    }
  }

  /*select(evt) {
      if(evt.button & 2) {
          if(typeof this.props.onContextSelect === "function") {
              this.props.onContextSelect(this.props.data,this.type,evt,this);
          }
          return;
      } else if(typeof this.props.onSelect ==="function") {
          this.props.onSelect(this.props.data,this.type,evt,this);
      }
  }*/

  static getAllowedObjectTypes() {
    return ALLOWABLE_OBJECTS;
  }

  selectMovableObject = (evt) => {
    if(typeof this.props.onClick ==="function") {
      this.props.onClick(this.props.data);
    }
  };

  get type() {
      return "OBJECT";
  }

  static getProperties(type) {
    switch(type) {
      case "CIRCLE":
        return CircleObject.getProperties();
      case "RECTANGLE":
        return RectangleObject.getProperties();
      case "LABEL":
        return LabelObject.getProperties();
      case "CURVE":
        return CurveObject.getProperties();
      case "CURVE_POLYGON":
        return CurvePolygonObject.getProperties();
      case "SECTION":
        return EditorSection.getProperties();
      default:
        return null;
    }
  }

  render() {
    const {
      dragHandler,
      isContextual,
      isSelected,
      onSelect,
      onClick,
      selectedTool,
      scale,
      ...props
    } = this.props;

    const { type } = props.data;

    const ChartObject  = OBJECTS[type];
    if (typeof ChartObject  === 'undefined') {
      return null; // sanity
    }

				// changing nub radius w.r.t viewport scale
    const nub_attributes = {
      r: (scale > 1.5) ? (EDIT_NUB_SIZE / 1.5) :
         ((scale < 0.75 && scale > 0.125) ? ( EDIT_NUB_SIZE / 0.5) :
         (( scale < 0.125 )?  ( EDIT_NUB_SIZE / 0.125) :
         EDIT_NUB_SIZE / scale ) 
         ),
      fill: OtColors.admin_blue,
      stroke: "transparent",
      strokeWidth: EDIT_NUB_SIZE
    };

    const classes = ['ot_seatingChartObject'];
    if (isSelected !== null && isSelected === false ) {
      classes.push('unselected');
    }
    else if (isSelected === true) {
      if (selectedTool.action) {
        classes.push(`${selectedTool.action.toLowerCase()}`);
      }
      else {
        classes.push('position');
      }
    }

    return (
      <g 
        className={classes.join(' ')}
        // {...dragHandler.mouseEvents}
        onClick={onClick ? (e) => onClick(props.data) : undefined}
      >
        <ChartObject
          {...props}
          nub_attributes={nub_attributes}
          isContextual={isContextual}
          isSelected={isSelected}
          selectedTool={selectedTool}
        />
      </g>
    );
  }
}
