import { createStore, applyMiddleware, compose  } from "redux";
import rootReducer from "reducers/rootReducer";
import { thunk } from "redux-thunk";
import BaseActionCreator from "factory/BaseActionCreator";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [ thunk ];

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
}
export const ReduxStore = configureStore();
BaseActionCreator.setDispatcher(ReduxStore);