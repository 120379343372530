import React from 'react';
import PropTypes from 'prop-types';
import 'sass/components/common/containers/_callout.scss';

export class Callout extends React.Component {
  constructor(props, type) {
    super(props);
    type = (typeof type === 'string' ?  type : null);
    this.state={type: type};
  }
  render() {
    let { className, isCollapsible, isMobile, disableCalloutStyle, isOpen, onClick, style, tag = 'div', title } = this.props;
    let { type } = this.state;
    let TagName = tag;
    return (
      <TagName
        style={style}
        className={`${disableCalloutStyle ? '' : 'ot_callout'} ${type ? type : ''} ${onClick ? 'ot_ci_clickable' : ''} ${isCollapsible ? 'ot_ci_slide' : ''} ${(isCollapsible && isOpen) ? 'ot_ci_open' : ''}  ${className ? className : ''}`
        }
        onClick={onClick} >
        { title  &&
          <h4>{title}</h4>
        }
        {this.props.children}
      </TagName>
    );
  }
}

export class DefaultCallout extends Callout { //gray
  constructor(props) {
    super(props, 'ot_calloutDefault');
  }
}

export class PrimaryCallout extends Callout { //customizable
  constructor(props) {
    super(props, 'ot_calloutPrimary');
  }
}

export class SecondaryCallout extends Callout { // customizable
  constructor(props) {
    super(props, 'ot_calloutPrimary');
  }
}

Callout.propTypes = {
  isCollapsible: PropTypes.bool,
  isMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  // title: PropTypes.object,
  onClick: PropTypes.func
};
