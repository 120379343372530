import React from 'react';
import {EDIT_NUB_SIZE,UnitConversion} from 'components/seatingChart/shared/SeatingChartConfig';
import {EditableObject} from 'components/seatingChart/shared/objects/EditableObject';
import {BeizerFunction,VectorUtils} from 'util/MathFunctions';
import {EditorConstants} from 'components/seatingChart/editor/EditorConstants';
import {OtColors} from 'util/OtColors';

const { ACTION_TOOLS } = EditorConstants;

/**
 * A curve object is a second order beizer curve. It has 2 end points and a single control point.
 * It may or may not have a fill color.
 */
export class CurveObject extends EditableObject
{
    /**
     * @static getAbsoluteBoundingRect - gets a rectangle con
     * @param  {type} object description
     * @return {type}        description
     */
    static getAbsoluteBoundingRect(object) {
    const {
      posX,
      posY,
      dX,
      dY,
      controlX,
      controlY
    } = object;

    const points = [
      [posX, posY],
      [posX + dX, posY + dY],
      [posX + controlX, posY + controlY]
    ];

    const curveMax = new BeizerFunction(points[0], points[1], 100, points[2]).evaluateAtStep(50);

    const [x, y] = VectorUtils.min(points[0], points[1], curveMax);
    const [width, height] = VectorUtils.subtract(VectorUtils.max(points[0], points[1], curveMax),[x, y]);
        return UnitConversion.convertRectangleToPixels({
            x,
            y,
            width,
            height,
      center:[
        x + width / 2,
        y + height / 2
      ]
        });
    }

  state = {
    }

    static getProperties() {
    return [
      EditorConstants.ACCESSORS.FILL,
         EditorConstants.ACCESSORS.STROKE,
         EditorConstants.ACCESSORS.STROKE_WIDTH,
         EditorConstants.ACCESSORS.OPACITY,
      EditorConstants.ACCESSORS.ROTATION
    ];
    }

    moveControlPoint(state,editMode) {
    const {
      data,
      data: {
        controlX = 0,
        controlY = 0,
        dX, dY
      },
      selectedTool,
      onPropertyChange
    } = this.props;

    const {
      posX = 0,
      posY = 0
    } = this.getPosition();

    const moveX = UnitConversion.xPixelsToUnits(state.dX);
    const moveY = UnitConversion.yPixelsToUnits(state.dY);

    if (selectedTool.action === ACTION_TOOLS[1] && typeof onPropertyChange==="function") {
      let newData = null;
      // editMode defines which point you are moving
      switch (editMode) {
        case "CONTROL":
          newData = {
            controlX: controlX + moveX,
            controlY: controlY + moveY
          };
          break;
        case "START":
          newData= {
            posX: posX + moveX,
            posY: posY + moveY,
            dX: dX - moveX,
            dY: dY - moveY,
            controlX: controlX - moveX,
            controlY: controlY - moveY
          };
          break;
        case "END":
          newData = {
            dX: dX + moveX,
            dY: dY + moveY
          };
          break;
      }
      if (!newData) {
        return false;
      }
      onPropertyChange(data, newData, this);
    }
    return true;
  }

  convertUnits() {
    const {
      data: {
        dX = 0,
        dY = 0,
        controlX = 0,
        controlY = 0
      }
    } = this.props;

    const {
      posX = 0,
      posY = 0
    } = this.getPosition();

    return {
      posX: UnitConversion.yUnitsToPixels(posX),
      posY: UnitConversion.yUnitsToPixels(posY),
      dX: UnitConversion.yUnitsToPixels(dX),
      dY: UnitConversion.yUnitsToPixels(dY),
      controlX: UnitConversion.xUnitsToPixels(controlX),
      controlY: UnitConversion.yUnitsToPixels(controlY)
    };
  }

  renderEditingNubs() {
    const {
      data,
      nub_attributes,
      selectedTool
    } = this.props;

    const {
      posX = 0,
      posY = 0,
      dX = 0,
      dY = 0,
      controlX = 0,
      controlY = 0
    } = this.convertUnits();

    const editing = selectedTool.action === ACTION_TOOLS[1];

    return (
      <g className="editIcons">
        <circle
          className={`object_nub nwse`}
          cx={posX}
          cy={posY}
          {...nub_attributes}
          onMouseDown={(e) => this.editObject("START")}
        />
        <circle
          className={`object_nub nwse`}
          cx={posX+dX}
          cy={posY+dY}
          {...nub_attributes}
          onMouseDown={(e) => this.editObject("END")} 
        />
        <circle
          className={`object_nub nwse`}
          cx={posX + controlX}
          cy={posY + controlY}
          {...nub_attributes}
          onMouseDown={(e) => this.editObject("CONTROL")}
        />
      </g>
    );
  }

  render() {
    const {
      data,
      isContextual,
      isSelected,
      selectedTool
    } = this.props;

    const editing = selectedTool && selectedTool.action === ACTION_TOOLS[1];

    const {
      posX = 0,
      posY = 0,
      dX = 0,
      dY = 0,
      controlX = 0,
      controlY = 0
    } = this.convertUnits();

    return (
      <g transform={`rotate(${data.rotation}, ${posX + dX / 2}, ${posY + dY / 2})`}>
        <path
          id={data.id}
          className="ot_curveObject chart_object"
          style={{
            opacity: data.opacity / 100
          }}
          d={`M ${posX} ${posY} q ${controlX} ${controlY}, ${dX} ${dY}`}
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
          strokeWidth={data.strokeWidth}
        />
        {isSelected && editing && this.renderEditingNubs()}
      </g>
    );
  }
}

