import React from "react";
import {UnitConversion} from "components/seatingChart/shared/SeatingChartConfig";
import {EditableObject} from "components/seatingChart/shared/objects/EditableObject";
import {EditorConstants} from 'components/seatingChart/editor/EditorConstants';

const { ACTION_TOOLS  } = EditorConstants;

export class CircleObject extends EditableObject
{
  static getAbsoluteBoundingRect(object) {
    const {
      posX: x,
      posY: y,
      rx,
      // ry = 0
    } = object;

    return UnitConversion.convertRectangleToPixels({
      x,
      y,
      width: 2 * rx,
      height: 2 * rx, // ry,
      center: [
        x + rx,
        y + rx // ry
      ]
    });
  }

  static getToolBoxPosition(object) {
    const {
      posX = 0,
      posY = 0,
      rx = 0,
      // ry = 0
    } = object;
    return {
      x: posX - rx,
      y: posY - rx
    };
  }

  static getProperties() {
    return [
      EditorConstants.ACCESSORS.FILL,
      EditorConstants.ACCESSORS.STROKE,
      EditorConstants.ACCESSORS.STROKE_WIDTH,
      EditorConstants.ACCESSORS.OPACITY,
      EditorConstants.ACCESSORS.RADIUS
    ];
  }

  moveControlPoint(state, editMode) {
    const {
      data, 
      data: {
        posX, posY, rx, // ry
      }, 
      selectedTool,
      onPropertyChange
    } = this.props;

    const dX = UnitConversion.xPixelsToUnits(state.dX);
    const dY = UnitConversion.yPixelsToUnits(state.dY);

    if (selectedTool.action === ACTION_TOOLS[1] && typeof onPropertyChange === "function") {
      let newData = null;
      switch(editMode) {
        case "TOP_LEFT":
          newData = {
            rx: Math.abs(rx - dX / 2)
          };
          break;
        case "TOP_MIDDLE":
          newData = {
            posY: posY + dY,
            rx: Math.abs(rx - dY / 2)
          };
          break;
        case "TOP_RIGHT":
          newData = {
            rx: Math.abs(rx + dX / 2)
          };
          break;
        case "MIDDLE_LEFT":
          newData = {
            posX: posX + dX,
            rx: Math.abs(rx - dX / 2)
          };
          break;
        case "MIDDLE_RIGHT":
          newData = {
            posX: posX + dX,
            rx: Math.abs(rx + dX / 2)
          };
          break;
        case "BOTTOM_LEFT":
          newData = {
            rx: Math.abs(rx - dX / 2)
          };
          break;
        case "BOTTOM_MIDDLE":
          newData = {
            posY: posY + dY,
            rx: Math.abs(rx + dY / 2)
          };
          break;
        case "BOTTOM_RIGHT": 
          newData = {
            rx: Math.abs(rx + dX / 2)
          };
          break;
      }

      if (!newData) {
        return false;
      }
      onPropertyChange(data, newData);
    }
    return true;
  }

  convertUnits() {
    let {
      data: {
        rx = 0,
//        ry = 0
      }
    } = this.props;

    let {
      posX = 0,
      posY = 0
    } = this.getPosition();

    posX = UnitConversion.xUnitsToPixels(posX);
    posY = UnitConversion.yUnitsToPixels(posY);
    rx = UnitConversion.xUnitsToPixels(rx);
//    ry = UnitConversion.yUnitsToPixels(ry);

    return {
      posX, posY, rx, // ry
    };
  }

  renderEditingNubs() {
    const {
      selectedTool,
      nub_attributes,
      data
    } = this.props;

    const {
      posX = 0,
      posY = 0,
      rx = 0,
      // ry = 0
    } = this.convertUnits();

    return (
      <g className="editIcons">
        <circle
          className={`object_nub nwse`}
          onMouseDown={(e) => this.editObject("TOP_LEFT")}
          cx={posX - rx}
          cy={posY - rx}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ns`}
          onMouseDown={(e) => this.editObject("TOP_MIDDLE")}
          cx={posX}
          cy={posY - rx}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nesw`}
          onMouseDown={(e) => this.editObject("TOP_RIGHT")}
          cx={posX + rx}
          cy={posY - rx}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ew`}
          onMouseDown={(e) => this.editObject("MIDDLE_LEFT")}
          cx={posX - rx}
          cy={posY}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ew`}
          onMouseDown={(e) => this.editObject("MIDDLE_RIGHT")}
          cx={posX + rx}
          cy={posY}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nesw`}
          onMouseDown={(e) => this.editObject("BOTTOM_LEFT")}
          cx={posX - rx}
          cy={posY + rx}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ns`}
          onMouseDown={(e) => this.editObject("BOTTOM_MIDDLE")}
          cx={posX}
          cy={posY + rx}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nwse`}
          onMouseDown={(e) => this.editObject("BOTTOM_RIGHT")}
          cx={posX + rx}
          cy={posY + rx}
          {...nub_attributes}
        />
      </g>
    );
  }

  render() {
    const {
      data,
      isSelected,
      selectedTool,
      isContextual
    } = this.props;

    let {
      posX = 0,
      posY = 0,
      rx = 0,
      // ry = 0
    } = this.convertUnits();

    if (rx < 0) {
      posX += 2 * rx;
      rx *= -1;
    }

//    if (ry < 0) {
//        posY += 2*ry;
//        ry *= -1;
//    }

    return (
      <g transform={`rotate(${data.rotation}, ${posX + rx}, ${posY + rx})`}>
        {/*
        <ellipse
          style={{opacity:data.opacity/100}}
          cx={posX+rx}
          cy={posY+ry}
          rx={rx}
          ry={ry}
          fill={data.fill}
          stroke={data.stroke}
        />
        */}
        <circle
          id={data.id}
          cx={posX} cy={posY}
          r={rx}
          className="ot_circleObject chart_object"
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
          strokeWidth={data.strokeWidth}
          style={{opacity:data.opacity/100}}
        />
        {isSelected
          && selectedTool.action === ACTION_TOOLS[1]
          && this.renderEditingNubs()}
      </g>
    );
  }
}
