import React from 'react'
import PropTypes from 'prop-types'
import 'sass/components/common/containers/_flex.scss'

/*
	  PROPS          DEFAULT         USE
	  ==================================================================================================
    columns="2"       1           -> accepts number or string => must be use together with fixed or fit
    fixed           false         -> when true: children are given a pre-set fixed width (equal width)
                                     if mobile and axisY = true -> elements will have 100% width
    fit             false         -> child elements will have a flexible width, except for those with
                                     an inline width style
    gap                2          -> x% gap between items. gap="0" means no gap between elements.
    margin          null          -> gives a specific margin to child elements;
    ------------------------------------------------------------------------------------------------
    alignX:     center || left   -> Horizontal Alignment (replaces old alignments props)
                                    Accepts strings: left, center, right, justify, space-around
                                    default if no columns or
    alignY:     middle || top     -> Vertical Alignment (replaces old alignment props)
                                    Accepts strings:  top, middle, bottom, stretch, baseline
    ------------------------------------------------------------------------------------------------
    inline          false         -> if true -> displays inline flex box
    axisY           false         -> on true -> changes direction to columns
    reverse         false         -> when true -> inverses order of elements
    wrap            false         -> changes default wrap mode
    isDisabled      false         -> returns a div wrapper only without affecting its children (isDisabled={!isMobile})
    className       string        -> Adds classname
    style           object        -> Overrides inline styles calculated by Flex component
    tag              div          -> Changes element type for the flex parent
    ------------------------------------------------------------------------------------------------
		TO BE DEPRECATED: base, stretch, spaceAround, left, center, right, top, middle, bottom, justify, around
*/

const Flex = ({
	alignX,
	alignY,
	axisY,
	base,
	reverse,
	stretch,
	wrap,
	isDisabled,
	left,
	center,
	right,
	top,
	middle,
	bottom,
	justify,
	around,
	inline,
	children,
	fit,
	fixed,
	gap = 2,
	columns,
	margin = 0,
	className,
	style,
	tag = 'div',
	...props
}) => {
	let TagName = tag
	let boxStyle = {}

	if (!isDisabled) {
		columns = parseInt(columns)
		gap = parseInt(gap)

		// flow...
		let flow = 'row'
		if (axisY || columns === 1) flow = 'column'
		if (reverse) flow += '-reverse'
		if (fixed || wrap) flow += ' wrap'

		// children alignment..
		let justifyContent, alignItems
		// default
		if (!alignX)
			alignX =
				!columns || columns === 1 || (children && children.length === 1)
					? 'center'
					: 'left'
		if (!alignY)
			alignY =
				!columns || columns === 1 || (children && children.length === 1)
					? 'middle'
					: 'top'

		//  old props support ...
		if ((left && center) || (right && center)) {
			center = false
			middle = true
		}

		if (left) alignX = 'left'
		if (center) alignX = 'center'
		if (right) alignX = 'right'
		if (justify) alignX = 'justify'
		if (around) alignX = 'space-around'
		if (top) alignY = 'top'
		if (center) alignY = 'middle'
		if (middle) alignY = 'middle'
		if (bottom) alignY = 'bottom'
		if (stretch) alignY = 'stretch'
		if (base) alignY = 'baseline' //

		// alingX : Horizontal Alignment...
		switch (alignX.toLowerCase()) {
			case 'left':
				if (axisY) alignItems = 'flex-start'
				else justifyContent = 'flex-start'
				break
			case 'center':
				if (axisY) alignItems = 'center'
				else justifyContent = 'center'
				break
			case 'right':
				if (axisY) alignItems = 'flex-end'
				else justifyContent = 'flex-end'
				break
			case 'justify':
				if (axisY) alignItems = 'space-between'
				else justifyContent = 'space-between'
				break
			case 'space-around':
				justifyContent = 'space-around'
				break
			default:
				if (axisY) alignItems = columns === 1 ? 'flex-start' : 'space-between'
				else {
					justifyContent =
						!columns || columns === 1 ? 'center' : 'space-between'
				}
		}

		// alignY: Vertical Alignment...
		switch (alignY.toLowerCase()) {
			case 'top':
				if (axisY) justifyContent = 'flex-start'
				else alignItems = 'flex-start'
				break
			case 'middle':
				if (axisY) justifyContent = 'center'
				else alignItems = 'center'
				break
			case 'bottom':
				if (axisY) justifyContent = 'flex-end'
				else alignItems = 'flex-end'
				break
			case 'justify':
				if (axisY) justifyContent = 'space-between'
				break
			case 'stretch':
				alignItems = 'stretch'
				break
			case 'baseline':
				alignItems = 'baseline'
				break
			default:
				if (axisY)
					justifyContent = !columns || columns === 1 ? 'center' : 'flex-start'
				else {
					alignItems = !columns || columns === 1 ? 'center' : 'flex-start'
				}
		}

		// flexbox style..
		boxStyle = Object.assign(
			{},
			{
				display: inline ? 'flex-inline' : 'flex',
				flexFlow: `${flow}`,
				justifyContent: `${justifyContent}`,
				alignItems: `${alignItems}`,
				width: '100%',
			},
			style
		)

		// children..........
		if (children && columns && columns > 1) {
			// several elements...
			children = React.Children.map(children, (child, i) => {
				if (child) {
					if (typeof child === 'string') child = <div>{child}</div>
					let newStyle = { overflow: 'hidden' }

					if (fixed) {
						let mRight = 0
						let mLeft = 0
						// children width and margins...
						if (left && (i + 1) % columns === 0) mRight = 'auto'
						else if (center) {
							if (i === 0 || i % columns === 0) mLeft = 'auto'
							if ((i + 1) % columns === 0) mRight = 'auto'
						} else if (right) {
							if (i === 0 || i % columns === 0) mLeft = 'auto'
							else mLeft = `${gap}%`
						} else if ((i + 1) % columns !== 0) {
							mRight = `${gap}%`
						}

						// if (className === 'inputGroup-container') console.log(gap, mRight, mLeft);

						newStyle = Object.assign({}, newStyle, {
							width: `${(100 - gap * (columns - 1)) / columns}%`,
							marginRight: `${mRight}`,
							marginLeft: `${mLeft}`,
						})
					} else if (fit && child.props) {
						// flex children
						// if (className === 'inputGroup-container') console.log('FIT');
						if (child.props.style && child.props.style.width) {
							newStyle = Object.assign({}, newStyle, {
								flex: `0 0 ${child.props.style.width}`,
							})
						} else newStyle = Object.assign({}, newStyle, { flex: 'auto' })
					}
					let childStyle = Object.assign(
						{},
						newStyle,
						child.props && child.props.style ? child.props.style : {}
					)
					return React.cloneElement(child, { style: childStyle })
				}
			})
		} else if (
			fixed &&
			(columns === 1 || (children && children.length === 1))
		) {
			children = React.Children.map(children, (child) => {
				if (child) {
					let newStyle = {
						overflow: 'hidden',
						width: '100%',
						marginLeft: '0px',
						marginRight: '0px',
					}

					let childStyle = Object.assign(
						{},
						newStyle,
						child.props && child.props.style ? child.props.style : {}
					)

					return React.cloneElement(child, { style: childStyle })
				}
			})
		} else if (!fixed && !fit) {
			//if (className === 'inputGroup-container') console.log('nofit no fix');
			children = React.Children.map(children, (child, i) => {
				if (child && i < children.length - 1) {
					let newStyle
					if (margin > 0) {
						if (axisY) newStyle = { marginBottom: `${margin}px` }
						else newStyle = { marginRight: `${margin}px` }
					}

					if (typeof child === 'string') child = <div>{child}</div>

					let childStyle = Object.assign(
						{},
						child.props && child.props.style ? child.props.style : {},
						newStyle
					)

					return React.cloneElement(child, { style: childStyle })
				} else return child
			})
		}
	}
	return (
		<TagName
			style={isDisabled ? style : boxStyle}
			className={className}
			role='group'
			{...props}
		>
			{children}
		</TagName>
	)
}

export default Flex

Flex.propTypes = {
	alignX: PropTypes.string,
	alignY: PropTypes.string,
	axisY: PropTypes.bool,
	children: PropTypes.any,
	className: PropTypes.string,
	columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fixed: PropTypes.bool,
	gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	reverse: PropTypes.bool,
	style: PropTypes.object,
	tag: PropTypes.string,
}
