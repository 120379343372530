import React from "react";
import {RectangleObject} from "./RectangleObject";
import {BeizerFunction,VectorUtils} from "util/MathFunctions";
import {EDIT_NUB_SIZE,UnitConversion} from "components/seatingChart/shared/SeatingChartConfig";
import {EditorConstants} from 'components/seatingChart/editor/EditorConstants';
import { deepEquals } from 'util/ObjectUtils';
import {OtColors} from 'util/OtColors';

const {  ACTION_TOOLS } = EditorConstants;

export class CurvePolygonObject extends RectangleObject {
  static getAbsoluteBoundingRect(object, position) {
    const pointsFromPath = (position && position.path) ?
      CurvePolygonObject.getCurvePolygonPointsFromPath(object, position.path) :
      CurvePolygonObject.getCurvePolygonPointsFromPath(object)

    const { posX, posY, x2, y2, x3, y3, x4, y4 } = pointsFromPath;
    const points = [[posX, posY], [x2, y2], [x3, y3], [x4, y4]];
    for (let i = 0; i < 4; i++) {
      const curve = new BeizerFunction(points[i], points[(i+1) %4], 100, [
        pointsFromPath[`cx${i+1}`],
        pointsFromPath[`cy${i+1}`]
      ]);
      points.push(curve.evaluateAtStep(50));
    }

    const [ x, y ] = VectorUtils.min(...points);
    const [ width, height ] = VectorUtils.subtract(VectorUtils.max(...points), [ x, y ]);
    return UnitConversion.convertRectangleToPixels({
      x,
      y,
      width,
      height,
      center: [x + width / 2, y + height / 2]
    });
  }

  static getProperties() {
    return [
      EditorConstants.ACCESSORS.FILL,
      EditorConstants.ACCESSORS.STROKE,
      EditorConstants.ACCESSORS.STROKE_WIDTH,
      EditorConstants.ACCESSORS.OPACITY,
      EditorConstants.ACCESSORS.ROTATION];
  }

  static getCurvePolygonPointsFromPath(object, pathFromPosition) {
    const path = pathFromPosition ?
      pathFromPosition : (
        object.path ? 
          object.path : null
      );

    if (path) {
      const points = path.replace(/,/g, '').split(" ");
      return {
        posX: parseFloat(points[1]),
        posY: parseFloat(points[2]),
        x1: parseFloat(points[1]),
        y1: parseFloat(points[2]),
        cx1: parseFloat(points[4]),
        cy1: parseFloat(points[5]),
        x2: parseFloat(points[6]),
        y2: parseFloat(points[7]),
        cx2: parseFloat(points[9]),
        cy2: parseFloat(points[10]),
        x3: parseFloat(points[11]),
        y3: parseFloat(points[12]),
        cx3: parseFloat(points[14]),
        cy3: parseFloat(points[15]),
        x4: parseFloat(points[16]),
        y4: parseFloat(points[17]),
        cx4: parseFloat(points[19]),
        cy4: parseFloat(points[20])
      };
    }
    else {
      return object;
    }
  }

  moveControlPoint(state, editMode) {
    const {
      onPropertyChange, data, selectedTool
    } = this.props;

    const {
      posX = 0, posY = 0, path
    } = this.getPosition();

    let {
      x1, y1, cx1, cy1,
      x2, y2, cx2, cy2,
      x3, y3, cx3, cy3,
      x4, y4, cx4, cy4
    } = CurvePolygonObject.getCurvePolygonPointsFromPath(data, path);

    let { dX, dY } = state;
    dX = UnitConversion.xPixelsToUnits(dX);
    dY = UnitConversion.yPixelsToUnits(dY);

    if (selectedTool.action === ACTION_TOOLS[1] && typeof onPropertyChange === "function") {
      const newData = null;
      switch(editMode) {
        case "TOP_LEFT":
          x1 += dX;
          y1 += dY;
          break;
        case "TOP_MIDDLE":
          cx1 += dX;
          cy1 += dY;
          break;
        case "TOP_RIGHT": 
          x2 += dX;
          y2 += dY;
          break;
        case "MIDDLE_LEFT": 
          cx4 += dX;
          cy4 += dY;
          break;
        case "MIDDLE_RIGHT":
          cx2 += dX;
          cy2 += dY;
          break;
        case "BOTTOM_LEFT":
          x4 += dX;
          y4 += dY;
          break;
        case "BOTTOM_MIDDLE": 
          cx3 += dX;
          cy3 += dY;
          break;
        case "BOTTOM_RIGHT": 
          x3 += dX;
          y3 += dY;
          break;
      }
      const path = CurvePolygonObject.getPathFromPoints(x1, y1, x1, y1, cx1, cy1, x2, y2, cx2, cy2, x3, y3, cx3, cy3, x4, y4, cx4, cy4);
      if (!deepEquals(path, data.path)) {
        onPropertyChange(data, { path }, this);
      }
    }
    return true;
  }

    static getPathFromPoints(posX, posY, x1, y1, cx1, cy1, x2, y2, cx2, cy2, x3, y3, cx3, cy3, x4, y4, cx4, cy4) {
      return `M ${x1} ${y1} Q ${cx1} ${cy1}, ${x2} ${y2} Q ${cx2} ${cy2}, ${x3} ${y3} Q ${cx3} ${cy3}, ${x4} ${y4} Q ${cx4} ${cy4}, ${x1} ${y1}`;
    }

    convertUnits() {
      const { data } = this.props;

      let {
        posX = 0, posY = 0, path
      } = this.getPosition();

      let {
        x1, y1, cx1, cy1,
        x2, y2, cx2, cy2,
        x3, y3, cx3, cy3,
        x4, y4, cx4, cy4
      } = CurvePolygonObject.getCurvePolygonPointsFromPath(data, path);

      posX = UnitConversion.xUnitsToPixels(posX);
      posY = UnitConversion.yUnitsToPixels(posY);
      x1 = UnitConversion.xUnitsToPixels(x1);
      y1 = UnitConversion.yUnitsToPixels(y1);
      cx1 = UnitConversion.xUnitsToPixels(cx1);
      cy1 = UnitConversion.yUnitsToPixels(cy1);
      x2 = UnitConversion.xUnitsToPixels(x2);
      y2 = UnitConversion.yUnitsToPixels(y2);
      cx2 = UnitConversion.xUnitsToPixels(cx2);
      cy2 = UnitConversion.yUnitsToPixels(cy2);
      x3 = UnitConversion.xUnitsToPixels(x3);
      y3 = UnitConversion.yUnitsToPixels(y3);
      cx3 = UnitConversion.xUnitsToPixels(cx3);
      cy3 = UnitConversion.yUnitsToPixels(cy3);
      x4 = UnitConversion.xUnitsToPixels(x4);
      y4 = UnitConversion.yUnitsToPixels(y4);
      cx4 = UnitConversion.xUnitsToPixels(cx4);
      cy4 = UnitConversion.yUnitsToPixels(cy4);

      return {
        posX, posY,
        x1, y1, cx1, cy1,
        x2, y2, cx2, cy2,
        x3, y3, cx3, cy3,
        x4, y4, cx4, cy4
      };
    }

    renderEditingNubs() {
      const {
        nub_attributes, selectedTool, data
      } = this.props;

      const editing = selectedTool.action === ACTION_TOOLS[1];

      const {
        x1, y1, cx1, cy1,
        x2, y2, cx2, cy2,
        x3, y3, cx3, cy3,
        x4, y4, cx4, cy4
      } = this.convertUnits();

      const circleSize = EDIT_NUB_SIZE;

      return (
        <g className="editIcons">
          <circle
            className={`object_nub nwse`}
            onMouseDown={(e) => this.editObject("TOP_LEFT")}
            cx={x1} cy={y1}
            {...nub_attributes}
          />
          <circle
            className={`object_nub ns`}
            onMouseDown={(e) => this.editObject("TOP_MIDDLE")}
            cx={cx1} cy={cy1}
            {...nub_attributes}
          />
          <circle
            className={`object_nub nesw`}
            onMouseDown={(e) => this.editObject("TOP_RIGHT")}
            cx={x2} cy={y2}
            {...nub_attributes}
          />
          <circle
            className={`object_nub ew`}
            onMouseDown={(e) => this.editObject("MIDDLE_LEFT")}
            cx={cx4} cy={cy4}
            {...nub_attributes}
          />
          <circle
            className={`object_nub ew`}
            onMouseDown={(e) => this.editObject("MIDDLE_RIGHT")}
            cx={cx2} cy={cy2}
            {...nub_attributes}
          />
          <circle
            className={`object_nub nesw`}
            onMouseDown={(e) => this.editObject("BOTTOM_LEFT")}
            cx={x4} cy={y4}
            {...nub_attributes}
          />
          <circle
            className={`object_nub ns`}
            onMouseDown={(e) => this.editObject("BOTTOM_MIDDLE")}
            cx={cx3} cy={cy3}
            {...nub_attributes}
          />
          <circle
            className={`object_nub nwse`}
            onMouseDown={(e) => this.editObject("BOTTOM_RIGHT")}
            cx={x3} cy={y3}
            {...nub_attributes}
          />
        </g>
      );
    }

    renderPath() {
      const {
        data, isContextual
      } = this.props;
      const {
        posX, posY,
        x1, y1, cx1, cy1,
        x2, y2, cx2, cy2,
        x3, y3, cx3, cy3,
        x4, y4, cx4, cy4
      } = this.convertUnits();
        /*let path = data.editing ? data.path :  `M ${posX} ${posY}
                Q ${cx1} ${cy1}, ${x2} ${y2}
                Q ${cx2} ${cy2}, ${x3} ${y3}
                Q ${cx3} ${cy3}, ${x4} ${y4}
                Q ${cx4} ${cy4}, ${posX} ${posY}
            `;*/
      return (
        <path
          id={data.id}
          className="ot_circleObject chart_object"
          style={{
            opacity: data.opacity / 100
          }}
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
          strokeWidth={data.strokeWidth}
          d={`M ${posX} ${posY}
              Q ${cx1} ${cy1}, ${x2} ${y2}
              Q ${cx2} ${cy2}, ${x3} ${y3}
              Q ${cx3} ${cy3}, ${x4} ${y4}
              Q ${cx4} ${cy4}, ${posX} ${posY}
          `}
        />
      );
    }

    /*renderPolygon() {
        let {data} = this.props;
        let {posX,posY,cx1,cy1,x2,y2,cx2,cy2,x3,y3,cx3,cy3,x4,y4,cx4,cy4} =this.convertUnits();
        return <path style={{opacity:data.opacity/100}} d={`M ${posX} ${posY}
          L ${cx1} ${cy1} L ${x2} ${y2}
          L ${cx2} ${cy2} L ${x3} ${y3}
          L ${cx3} ${cy3} L ${x4} ${y4}
          L ${cx4} ${cy4} L ${posX} ${posY}
    `} fill={data.fill} stroke={data.stroke} strokeWidth={data.strokeWidth}/>;
    }*/

    render() {
      const {
        data,
        position,
        isSelected,
        selectedTool
      } = this.props;

      const boundingRect = CurvePolygonObject.getAbsoluteBoundingRect(data, position);
      const [ centerX, centerY ] = boundingRect.center;
      const noCurve = data.noCurve && data.noCurve !== "false";

      return (
        <g transform={`rotate(${data.rotation}, ${centerX}, ${centerY})`}>
          {/*noCurve?this.renderPolygon():this.renderPath()*/}
          {this.renderPath()}
          {isSelected
            && selectedTool.action === ACTION_TOOLS[1]
            && this.renderEditingNubs()}
        </g>
      );
    }
}
