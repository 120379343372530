import { VectorUtils, GraphicsUtils } from "../util/MathFunctions"

/**
 * An abstraction of events for handling mouse/touch drags across multiple devices and browsers
 * Each event is fired with a drag state object which defines the state of the drag:  start points,
 * current mouse/touch positions, whenther it is in the middle of a drag. The abstract events supported by this object are:
 * onPinch-> a mobile pinch action
 * onDragStart-> a touch/mouse down event
 * onDragEnd-> a touch/mouse up event
 * onDrag -> a mouse move event while the mouse/touch is still pressed.
 * onClick -> when the mouse is clicked or tap. In other words when the mouse/touch is pressed and releassed without a drag mouseLocation
 *
 * These events may be added as functions to the object. The actual events to add to the dom/react object may be retrieved using
 * the mouseEvents get property of this object.
 */
export class MouseDragHandler {
  constructor(options={stopPropagation:true,preventDefault:true}) {
    this.state= {
      mouseDown:false,
      dragging:false,
      dX:0,
      dY:0,
      dWidth: 0,
      dHeight:0,
      posX: null,
      posY:null,
      doClick:false,
      startPositions:[],
      currentPositions:null,
      buttons:0
    }
    let bounds=options && options.bounds
    if(bounds && bounds.min) {
      this.minBound(bounds.min);
    }
    if(bounds &&  bounds.max) {
      this.minBound(bounds.max);
    }
    this.events={}
    this.options=options;
    document.addEventListener("mouseup",()=>{
      this.state.mouseDown=false;
      this.state.buttons=0;
    })
    document.addEventListener("mouseleave",()=>{
      this.state.mouseDown=false;
      this.state.buttons=0;
    })
    this.handleMouseDown=this.handleMouseDown.bind(this);
  }
  name(name) {
    this.name=name;
    return this;
  }
  onDragStart(evt) { // console.log('onDragStart');
    this.events.onDragStart=evt;
    return this;
  }
  onDrag(evt) { // console.log('onDrag');
    this.events.onDrag=evt;
    return this;
  }
  onDragEnd(evt) { //console.log('onDragEnd');
    this.events.onDragEnd=evt;
    return this;
  }
  onClick(evt) { //console.log('onClick');
    this.events.onClick=evt;
    return this;
  }
  onPinch(evt) {
    this.events.onPinch=evt;
    return this;
  }
  get minBound() {
    return (this.bounds && this.bounds.min) || [Number.MIN_VALUE,Number.MIN_VALUE];
  }
  get maxBound() {
    return (this.bounds && this.bounds.max) || [Number.MAX_VALUE,Number.MAX_VALUE];
  }

  /**
   * set minBound - sets a point that is the minimum point a mouse position/touch can be
   *
   * @param  {number[]} bound the minimum bound point for mouse events
   */
  set minBound(bound) {
    this.bounds = this.bounds || {};
    this.bound.min=bound;
    this.updatePosition(this.state.posX,this.state.posY);
  }
  /**
   * set maxBound - sets a point that is the maximum point a mouse position/touch can be
   *
   * @param  {number[]} bound the maximum bound point for mouse events
   */
  set maxBound(bound) {
    this.bounds = this.bounds || {};
    this.bound.min=bound;
    this.updatePosition(this.state.posX,this.state.posY);
  }

  /**
   * get mouseEvents - returns events that may be added to a react or dom object
   *
   * @return {object}  object where each key is an actual dom event name and the value is the function to handle the event
   */
  get mouseEvents() {
    return {
      onMouseUp:this.mouseUpHandler,
      onMouseLeave:this.mouseUpHandler,
      onMouseDown:this.mouseDownHandler,
      onMouseMove:this.mouseMoveHandler,
      onTouchStart:this.touchStartHandler,
      onTouchMove: this.touchMoveHandler,
      onTouchEnd:this.touchEndHandler
    };
  }

  /**
   * get touchMoveHandler
   * @return {function}  function to handle touchMove event
   */
  get touchMoveHandler() {
    return this.handleDrag.bind(this,true);
  }
  /**
   * get touchStartHandler
   * @return {function}  function to handle touchStart event
   */
  get touchStartHandler() {
    return this.handleMouseDown.bind(this,true)
  }
  /**
   * get touchEndHandler
   * @return {function}  function to handle touchEnd event
   */
  get touchEndHandler() {
    return this.handleMouseUp.bind(this,true)
  }
  /**
   * get mouseMoveHandler
   * @return {function}  function to handle mouseMove event
   */
  get mouseMoveHandler() {
    return this.handleMouseMove.bind(this,false);
  }
  /**
   * get mouseUpHandler
   * @return {function}  function to handle mouseUp event
   */
  get mouseUpHandler() { // console.log('MOUSEUPHANDLER');
    return this.handleMouseUp.bind(this,false);
  }
  /**
   * get mouseDownHandler
   * @return {function}  function to handle mouseDown event
   */
  get mouseDownHandler() { //console.log('MOUSEDOWNHANDLER');
    return this.handleMouseDown.bind(this,false);
  }
  boundPosition(posX,posY) {
    return VectorUtils.bound([posX,posY],this.minBound,this.maxBound);
  }

  /**
   * updatePosition - updates the state based on event
   *
   * @param  {type} isTouch true if the event is a touch event. false if it is a mouse event
   * @param  {type} evt     description
   * @return {type}         description
   */
  updatePosition(isTouch, evt) {
    if(evt) {
      let currentPositions;
      if(!isTouch) { //handle mouseEvents
        let {clientX ,clientY} = evt.nativeEvent;
        currentPositions = [this.boundPosition(clientX, clientY)];
      } else { //for mobile touch events
        if(!evt.touches.length) { //handles multiple touch point positions
          currentPositions=[[this.state.posX, this.state.posY]];
        } else { //handles multiple touch point positions
          currentPositions=[]
          for(let i = 0; i < evt.touches.length; i++) { //add each touch point to the state
            let {pageX, pageY} = evt.touches[i];
            currentPositions.push(this.boundPosition(pageX, pageY));
          }
        }
      }
      //update positions
      let [posX, posY] = currentPositions[0];
      let deltas = currentPositions.map((item, index)=>{
        if(!this.state.currentPositions || index >= this.state.currentPositions.length) {
          return [0,0];
        }
        return VectorUtils.subtract(item, this.state.currentPositions[index]);
      });
      let [dX,dY] = deltas[0];
      let scale = null, currentRectangle = null;
      let [dWidth, dHeight] = [null, null];
      if(currentPositions.length >= 2) { //handles pinch calculations and determines how much the pich was
        currentRectangle = GraphicsUtils.rectangleFrom2Points(currentPositions[0], currentPositions[1]);
        let lastRectangle = this.state.currentRectangle || {width : 0, height : 0};
        [dWidth, dHeight] = VectorUtils.subtract([currentRectangle.width ,currentRectangle.height],
                                                  [lastRectangle.width, lastRectangle.height]);
        let diffRatio = VectorUtils.componentDivide([currentRectangle.width , currentRectangle.height],
                                                  [lastRectangle.width, lastRectangle.height]);
        scale = Math.max(...diffRatio); //scale is the pinch ratio
      }
      this.state = Object.assign(this.state,{
        posX,
        posY,
        currentPositions,
        currentRectangle,
        scale,
        deltas,
        dX,
        dY,
        dWidth,
        dHeight
      })
      return currentPositions;
    }
  }

  /**
   * handleMouseDown - the actual event that gets handled during mouse mown. isTouch will be prebound
   *
   * @param  {boolean} isTouch if event is touch event
   * @param  {Event} evt     the actual event
   * @return {boolean}        false if the event should not be propagated
   */
  handleMouseDown(isTouch, evt) {
    //this.options.stopPropagation && evt.stopPropagation();
    this.options.preventDefault && evt.preventDefault();
    this.state.buttons = evt.buttons;
    this.state.mouseDown = true;
    this.state.doClick = true;
    let positions = this.updatePosition(isTouch, evt);
    this.state.startPositions = positions;
    if(positions.length === 1 && !this.state.dragging && this.events.onDragStart) {
      this.events.onDragStart(this.state, this, evt);
    }
    //return !this.options.stopPropagation;
  }

  /**
   * handleDrag - the actual event that gets handled during mouse move. isTouch will be prebound
   *
   * @param  {boolean} isTouch if event is touch event
   * @param  {Event} evt     the actual event
   * @return {boolean}        false if the event should not be propagated
   */
  handleDrag(isTouch, evt) {
    this.options.stopPropagation && evt.stopPropagation();
    this.options.preventDefault && evt.preventDefault();
    this.state.dragging = true;
    const position = this.updatePosition(isTouch, evt);
    if (position.length === 2) { //multiple touchpoints will trigger a pinch operation
      if (this.events.onPinch) {
        this.events.onPinch(this.state, this, evt);
      }
    }
    else if (this.events.onDrag) {
      this.events.onDrag(this.state, this, evt);
    }

    return !this.options.stopPropagation;
  }

  handleMouseMove(isTouch, evt) {
    if (!this.state.mouseDown) return;

    this.handleDrag(isTouch, evt);
  }

  /**
   * handleMouseUp - the actual event that gets handled during mouse up. isTouch will be prebound
   *
   * @param  {boolean} isTouch if event is touch event
   * @param  {Event} evt     the actual event   
   * @return {boolean}        false if the event should not be propagated
   */
  handleMouseUp(isTouch,evt) {
    this.options.stopPropagation && evt.stopPropagation();
    this.options.preventDefault && evt.preventDefault();
    let lastDragState=this.state.dragging;
    let positions=this.updatePosition(isTouch,evt);
    let startPosition=this.state.startPositions[0];
    if(!startPosition || !positions || positions.length===0) {
      this.state.mouseDown=this.state.doClick=this.state.dragging=false;
      return;
    }
    if(positions.length===1 && positions[0].some((item,index)=>item!==startPosition[index])) {
      this.state.doClick=false;
    }
    if(positions.length===1 && this.state.doClick && this.events.onClick) {
      this.events.onClick(this.state,this,evt);
    }
    this.state.mouseDown=this.state.doClick=this.state.dragging=false;
    this.state.posX=this.state.posY=null;
    if(positions.length===1 && lastDragState && this.events.onDragEnd) {
      this.events.onDragEnd(this.state,this,evt);
    }
    return !this.options.stopPropagation;
  }
}
