export const clone = (obj) =>{
  if (obj === null || typeof(obj) !== 'object') return obj;

	let temp;
  if (obj instanceof Date)
    temp = new obj.constructor();
  else
    temp = obj.constructor();

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      temp[key] = clone(obj[key]);
    }
  }

  return temp;
}


export const deepEquals = (obj1,obj2) => {
  if(obj1===obj2) {
    return true;
  }
  if(obj1 && obj2 && typeof obj1 ==="object" && typeof obj2==="object") {
    if(Object.keys(obj1).length!==Object.keys(obj2).length) {
      return false;
    }
    let keys=Object.keys(obj1);
    for(let i=0;i<keys.length;i++) {
      let key=keys[i];
      let areEqual = Object.deepEquals(obj1[key],obj2[key]);
      if(!areEqual) {
        return false;
      }
    }
    return true;
  }
  return obj1 === obj2;
}
