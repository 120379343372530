import React from "react";
import { getRangeLabel } from 'util/Utils';
import { SvgChevron, SvgWheelchairNoColor, CompanionNoColor } from "components/common/icons/SvgIcons";
import './../../../sass/components/seatingChart/seatSelection/mapToolbars.scss';
import {OtColors} from 'util/OtColors';

export class MapLegend extends React.PureComponent {
	state = {
		dropDownVisible: true
	}
	render(){
		let {priceLevels, showOutline, currencySymbol} = this.props;
		let {dropDownVisible} = this.state;
		let priceKeys = [];
		let altPriceKeys = [];
		if (showOutline) {
			priceKeys.push(
				<li key="seats"
					className={`sectionView legend ${showOutline ? 'showcased' : 'hidden'}`}
					style={{color: `${OtColors.admin_blue}`}} >
					<span>Seats Available</span>
				</li>);
		} else {

			priceLevels.forEach( ( pl, index ) => {
				let { min,
					max,
					priceLevel: { color, name, type } 
				} = pl;
			
				let rangeLabelName = getRangeLabel( max, min, currencySymbol );
				
				let iconType = '';
				if ( type === 'HANDICAP' ) {
					rangeLabelName = 'Accessible Seating';
					// iconType = 'accessible_seating';
				}
				if ( type === 'WHEELCHAIR_COMPANION' || type === 'WHEELCHAIRCOMPANION' ) {
					rangeLabelName = 'Companion Seating';
					// iconType = 'companion';
				}
			
				if ( type === 'NORMAL' ) {
					priceKeys.push(
						<li key={ index }
							className={ `legend ${iconType} ${ !showOutline ? 'showcased' : 'hidden'}` }
							style={ { color: `${color}` } }>
							<div className="ddSeatDetails">
								<span className="seatName">{ name }</span>
								<span>{ rangeLabelName }</span>
							</div>
						</li>
					);
				} else {
					altPriceKeys.push(
						<li key={ index }
							className={ `legend ${iconType}`}>
							<div className="ddSeatDetails altSeats">
								{type === 'HANDICAP' ? 
									<SvgWheelchairNoColor/>
								:
									<CompanionNoColor/>
								}
								<span>{ rangeLabelName }</span>
							</div>
						</li>
					);
				}
			});

		}

		priceKeys.push(
			<li key="soldout" className="legend sold_out showcased">
				<span className="seatName">Unavailable</span>
			</li>
		);

		const legendOpener = () => {
			this.setState({dropDownVisible: !dropDownVisible})
		}

		return (
			<div className = "ot_mapLegend">
				{showOutline ?
					priceKeys
				: 
					<div className="legendDropdown">
						<button onClick={() => legendOpener()} className={this.state.dropDownVisible && "open"}>
							Legend
							<SvgChevron/>
						</button>
						{dropDownVisible &&
							<div className="legendDropdown_content">
								{priceKeys}
								{altPriceKeys.length > 0 &&
									<React.Fragment>
										<div className="divider"></div>
										{altPriceKeys}
									</React.Fragment>
								}
							</div>
						}
					</div>
				}
			</div>
		);
	}
}
