import React from 'react'
import { diff } from 'deep-object-diff'
// components
import { Row } from 'components/seatingChart/seatSelection/Row'
import { SeatingChartObject } from 'components/seatingChart/shared/objects/SeatingChartObject'
import {
	SEAT_SIZE,
	SEAT_SPACING,
	SEAT_SPACING_Y,
} from 'components/seatingChart/shared/SeatingChartConfig'
// util
import { LinearFunction, VectorUtils, GraphicsUtils } from 'util/MathFunctions'

export class Section extends SeatingChartObject {
	state = { isDragging: false }

	get type() {
		return 'SECTION'
	}

	shouldComponentUpdate(nextProps, nextState) {
		const s = diff(nextState, this.state)
		if (Object.keys(s).length !== 0) {
			return true
		}
		return Object.keys(diff(nextProps, this.props)).length !== 0
	}

	componentDidMount() {
		let sectionName = this.camelCase(this.props.data.name)
		let { width: sectionWidth } = Section.getBoundingRect(this.props.data)
		let textWrap = document.getElementById(sectionName)
		let textWrapText = textWrap.textContent
		this.textEllipsis(textWrap, textWrapText, sectionWidth)
	}

	textEllipsis = (el, text, width) => {
		width = width - 80
		if (typeof el.getComputedTextLength !== 'undefined') {
			while (el.getComputedTextLength() > width) {
				text = text.slice(0, -1)
				el.textContent = text + '...'
				if (el.textContent === '...') break
			}
		} else if (typeof el.getSubStringLength !== 'undefined') {
			el.textContent = text
			let len = text.length
			while (el.getSubStringLength(0, len--) > width) {
				el.textContent = text.slice(0, len) + '...'
				if (el.textContent === '...') break
			}
		} else if (typeof el.getBBox !== 'undefined') {
			// the last fallback
			while (el.getBBox().width > width) {
				text = text.slice(0, -1)
				// we need to update the textContent to update the boundary width
				el.textContent = text + '...'
				if (el.textContent === '...') break
			}
		}
	}

	camelCase = (str) => {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index == 0 ? word.toLowerCase() : word.toUpperCase()
			})
			.replace(/\s+/g, '')
	}

	static findSeatPosition(section, seatId) {
		let { skewX, rows } = section
		const skew = new LinearFunction(
			skewX || 0,
			skewX < 0 ? -skewX * (rows.length - 1) : 0
		).generate()
		const rowY = Section.getRowYFunction(section).generate()
		const sectionSize = Section.getMaxRowSize(section)
		const width = Section.calculateWidth(section)
		for (let i = 0; i < rows.length; i++) {
			let seatPosition = Row.findSeatPosition(
				sectionSize,
				width,
				section,
				section.rows[i],
				seatId
			)
			let rowPosition = [skew.next().value[1], rowY.next().value[1]]
			if (seatPosition) {
				return VectorUtils.add(rowPosition, seatPosition)
			}
		}
	}

	static getAbsoluteBoundingRect(data) {
		const rect = Section.getBoundingRect(data)
		return GraphicsUtils.rotateRectangle(rect, data.rotation, rect.center)
	}

	static getSectionPosition(data) {
		return [
			data.posX * (SEAT_SIZE + SEAT_SPACING),
			data.posY * (SEAT_SIZE + SEAT_SPACING_Y),
		]
	}

	static getBoundingRect(data) {
		const [x, y, width, height] = [
			...Section.getSectionPosition(data),
			Section.calculateWidth(data),
			Section.calculateHeight(data),
		]

		return {
			x,
			y,
			width,
			height,
			center: [x + width / 2, y + height / 2],
		}
	}

	static getMaxLabelWidth(data) {
		const maxChars = data.rows
			.map((row) => row.name)
			.reduce((acc, name) => Math.max(name.length, acc), 0)

		return (maxChars * SEAT_SIZE) / 2
	}

	static getMaxRowSize(data) {
		if (data.maxPosition === null) {
			return null
		}
		return 1 + (data.maxPosition - data.minPosition)
	}

	static calculateHeight(data) {
		const skewY = Math.abs(data.skewY)
		const rowLocations = new LinearFunction(
			SEAT_SIZE + data.rowSpacing + skewY,
			0
		)
		return rowLocations.evaluateAt(data.rows.length + 1)
	}

	static calculateWidth(data) {
		const skewX = Math.abs(data.skewX)
		const seatPositions = Section.getMaxRowSize(data) + 1 // + 1 for row label
		return (
			seatPositions * SEAT_SIZE +
			(seatPositions + skewX + 1) * SEAT_SPACING +
			Section.getMaxLabelWidth(data)
		)
	}

	static getRowYFunction(data) {
		return new LinearFunction(SEAT_SIZE + data.rowSpacing, 0)
		// return new LinearFunction((SEAT_SIZE + SEAT_SPACING_Y),0);
	}

	getRowSize = (data) => {
		return {
			height: SEAT_SIZE + data.rowSpacing,
			width: Section.calculateWidth(data),
		}
	}

	handleSectionClick = (evt) => {
		let { data } = this.props
		let { height: sectionHeight, width: sectionWidth } =
			Section.getBoundingRect(data)
		if (this.props.onSectionClick && !this.state.isDragging) {
			this.props.onSectionClick(this, data, [sectionWidth, sectionHeight])
			evt.stopPropagation()
		}
		this.setState({
			isDragging: false,
		})
	}

	handleSectionTouchMove = () => {
		this.setState({
			isDragging: true,
		})
	}

	seatOpacity = () => {
		if (!this.props.showOutline) {
			return 1
		}
		return 0
	}

	shouldDisableSeatSelection = () => {
		return this.seatOpacity() < 1
	}

	renderRows() {
		let {
			filtersLength,
			rotate,
			onEditSelect,
			curve,
			skewX,
			skewY,
			data,
			seatAlign,
			filter,
			onSeatSelect,
			onSeatUnselect,
			seatsToSelect,
			onlySeatSelection,
			priceLevelFilters,
			priceRangeFilterApplied,
			isSmallDevice,
			isMobile,
			selectedSeats = {},
			showSeatNumber,
		} = this.props
		const rowData = data.rows
		const skew = new LinearFunction(
			skewX || 0,
			skewX < 0 ? -skewX * (rowData.length - 1) : 0
		).generate()
		let rowY = Section.getRowYFunction(data)
		let { width, height } = Section.getBoundingRect(data)
		const { width: rowWidth, height: rowHeight } = this.getRowSize(data)

		const maxRowSize = Section.getMaxRowSize(data)
		const labelWidth = Section.getMaxLabelWidth(data)
		let rows = rowData.map((row, i) => {
			let rowLabelLocation = VectorUtils.add(
				Row.calculateLabelPosition(row, {
					width,
					curve,
					skewY,
					seatAlign,
					sectionSize: maxRowSize,
					sectionOffset: data.minPosition,
				}),
				[labelWidth, 0]
			)
			let displayRowLabel =
				(data.availableSeats && data.availableSeats > 0) ||
				(data.priceLevelIds && data.priceLevelIds.length > 0)
			let spacer = 0

			if (row.name.length > 3) {
				let numOfChars = row.name.length - 3
				spacer = 5 * numOfChars + 15
			}

			return (
				<svg
					key={i}
					x={skew.next().value[1]}
					y={rowY.evaluateAt(row.rowNumber)}
					width={rowWidth}
					height={rowHeight}
				>
					<g
						transform={`translate(${rowLabelLocation[0] + 2 - labelWidth} ${rowLabelLocation[1]})`}
					>
						<text
							textAnchor='right'
							y={SEAT_SIZE / 4}
							x={spacer * -1}
							transform={`rotate(${-rotate || 0},${SEAT_SIZE / 4},${SEAT_SIZE / 4})`}
							style={{
								fontSize: SEAT_SIZE / 2,
								dominantBaseline: 'central',
								cursor: 'default',
								display: displayRowLabel ? '' : 'none',
							}}
						>
							{row.name}
						</text>
					</g>
					<svg x={0} width={rowWidth} height={rowHeight}>
						<Row
							disableSeatSelect={this.shouldDisableSeatSelection()}
							data={row}
							sectionId={data.id}
							sectionOffset={data.minPosition}
							showSeatNumber={showSeatNumber}
							textRotation={rotate}
							sectionSize={maxRowSize}
							seatAlign={seatAlign}
							curve={this.props.curve}
							width={width}
							skewY={skewY}
							priceLevelFilters={priceLevelFilters}
							seatsToSelect={seatsToSelect}
							onlySeatSelection={onlySeatSelection}
							isFixedPackageSeating={this.props.isFixedPackageSeating}
							disabledSeatsForFixedPlusPackage={
								this.props.disabledSeatsForFixedPlusPackage
							}
							filter={filter}
							filtersLength={filtersLength}
							onEditSelect={onEditSelect}
							onSeatSelect={onSeatSelect}
							isSmallDevice={isSmallDevice}
							isMobile={isMobile}
							onSeatUnselect={onSeatUnselect}
							selectedSeats={selectedSeats}
						/>
					</svg>
				</svg>
			)
		})
		return rows
	}

	render() {
		let {
			data,
			fillOpacity,
			priceLevelFilters,
			priceRangeFilterApplied,
			rotate,
			showOutline,
			skewX,
			skewY,
		} = this.props

		let { height: sectionHeight, width: sectionWidth } =
			Section.getBoundingRect(data)
		const clickable = showOutline && data.outline
		const center = [sectionWidth / 2, sectionHeight / 2]

		let seatOpacity = this.seatOpacity()
		let isSectionAvailable = data.availableSeats && data.availableSeats > 0
		let highlight = false
		if (priceLevelFilters.length > 0) {
			highlight = data.priceLevelIds.some(
				(r) => priceLevelFilters.indexOf(r) >= 0
			)
		} else if (
			typeof priceRangeFilterApplied !== 'undefined' &&
			priceRangeFilterApplied !== null &&
			!priceRangeFilterApplied
		) {
			highlight = true
		}

		let sectionNameId = this.camelCase(`${data.name}`)

		return (
			<g
				className={`section ${clickable && isSectionAvailable ? 'clickable' : 'not_clickable'}
                              ${isSectionAvailable ? 'available' : 'not_available'} ${isSectionAvailable !== true && this.props.isSmallDevice === true ? 'mobile_not_clickable' : ''}`}
				onClick={
					clickable && isSectionAvailable ? this.handleSectionClick : undefined
				}
				onMouseDown={null}
				onTouchMove={clickable ? this.handleSectionTouchMove : undefined}
				onTouchEnd={clickable ? this.handleSectionClick : undefined}
				transform={`rotate(${rotate || 0},${sectionWidth / 2},${sectionHeight / 2})`}
			>
				{clickable && (
					<g className='animate' style={{ opacity: 1 }}>
						<path
							className={`section_area ${!highlight ? 'filteredOut' : ''}`}
							fill={`${isSectionAvailable > 0 ? '#01BEFF' : '#EEEEEE'}`}
							fillOpacity={fillOpacity}
							d={data.outline}
						></path>
					</g>
				)}

				<svg
					style={{ opacity: Math.max(1 - 2 * seatOpacity, 0) }}
					width={sectionWidth}
					height={sectionHeight}
				>
					<text
						textAnchor='middle'
						id={sectionNameId}
						x={center[0]}
						y={center[1]}
						style={{
							fontSize: (3 * SEAT_SIZE) / 2,
							textOverflow: 'ellipsis',
							pointerEvents: 'none',
						}}
						stroke='black'
						transform={`rotate(${-1 * rotate},${sectionWidth / 2},${sectionHeight / 2})`}
					>
						{data.name}
					</text>
				</svg>
				{!clickable && (
					<g className='animate' style={{ opacity: seatOpacity }}>
						{this.renderRows()}
					</g>
				)}
			</g>
		)
	}
}
