import {VectorUtils} from "./VectorUtils"
export class BeizerFunction {
  constructor(start=[0,0],end=[100,100],steps=100, ...controlPoints){
    this.currentStep=0;
    this.steps=steps-1;
    this.start=start;
    this.end=end;
    this.controlPoints=controlPoints;
    this.increment=VectorUtils.magnitude(VectorUtils.subtract(end,start))/steps;
  }
  interpolate(controlPoints,pctStep) {
    let ret=[];
    for(let currentPoint=0, nextPoint=1; nextPoint<controlPoints.length;currentPoint++,nextPoint++) {
      let p1=controlPoints[currentPoint];
      let p2=controlPoints[nextPoint];
      let slopeVector=VectorUtils.scalarMultiply(VectorUtils.subtract(p2,p1),pctStep);
      ret.push(VectorUtils.add(p1,slopeVector));
    }
    return ret;
  }
  evaluateAt(x,y) {
    let pctStep;
    if(VectorUtils.isVector(x)) {
      [x,y]=x;
    }
    if(typeof y === "number" || VectorUtils.isVector(x)) {
      let distance=VectorUtils.magnitude(VectorUtils.subtract(this.end,this.start))
      pctStep=VectorUtils.magnitude(VectorUtils.subtract([x,y],this.start))/distance;
    } else {
      pctStep=Math.abs((x-this.start[0])/(this.end[0]-this.start[0]));
    }
    pctStep=Math.max(Math.min(pctStep,1),0)
    if(pctStep>1 || pctStep<0) {
      throw new Error(`Argument out of function range: ${x}\r\n Range: [${this.start[0]},${this.end[0]}]`)
    }

    return this.evaluateAtPct(pctStep)
  }
  evaluateAtPct(pctStep) {
      let allControlPoints=[this.start,...this.controlPoints,this.end];
      while(allControlPoints.length>1) {
        allControlPoints=this.interpolate(allControlPoints,pctStep);
      }
      return allControlPoints[0];
  }
  evaluateAtStep(step) {
    if(step>this.steps || step<0) {
      throw new Error("No such step.")
    }
    return this.evaluateAtPct(step/this.steps||1);
  }
  generate(stepIncrement=1) {
    return function*(step, stepIncrement) {
      while(step<=this.steps) {
        yield this.evaluateAtStep(step);
        step+=stepIncrement;
      }
    }.bind(this)(0,stepIncrement)
  }
}
export let Beizer = function*(start=[0,0],end=[100,100],steps=100, ...controlPoints){
  let beizer=new BeizerFunction(start,end,steps,...controlPoints);
  yield* beizer.generate();
}
