import React from 'react'
import { diff } from 'deep-object-diff'
import { BeizerFunction, LinearFunction } from 'util/MathFunctions'
import Seat from 'components/seatingChart/seatSelection/Seat'
import {
	SEAT_RADIUS,
	SEAT_SIZE,
	SEAT_SPACING,
} from 'components/seatingChart/shared/SeatingChartConfig'

export class Row extends React.Component {
	static findSeatPosition(sectionSize, width, section, row, seatId) {
		let { skewY, seatAlign, curve, minPosition: sectionOffset } = section

		let seat = row.seats.filter((seat) => seat.id === seatId)[0]
		if (!seat) {
			return null
		}
		const skew = new LinearFunction(
			skewY,
			skewY < 0 ? -skewY * (sectionSize - 1) : 0
		)
		let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
			width / 2,
			curve * 2,
		])
		const seatSpacing = new LinearFunction(
			SEAT_SIZE + SEAT_SPACING,
			SEAT_SPACING
		)
		const position = Row.getActualPosition(
			row,
			seat.position,
			seatAlign,
			sectionSize,
			sectionOffset
		)
		const x = seatSpacing.evaluateAt(position)
		const y = curveGen.evaluateAtStep(position)[1] + skew.evaluateAt(position)
		return [x, y]
	}

	static getActualPosition(
		row,
		position,
		seatAlign,
		sectionSize,
		sectionOffset
	) {
		let offset = 0
		let { maxPosition, minPosition } = row
		// normalize positions according to section min
		// this removes empty spaces at start of row.
		maxPosition -= sectionOffset
		minPosition -= sectionOffset
		position -= sectionOffset
		const positions = maxPosition - minPosition + 1
		switch (seatAlign) {
			case 'LEFT':
				position = position - minPosition
				break
			case 'RIGHT':
				offset = sectionSize - positions
				position = position - minPosition + offset
				break
			case 'CENTER':
				offset = (sectionSize - positions) / 2
				position = position - minPosition + offset
				break
		}
		return position
	}

	static calculateLabelPosition(data, props) {
		let {
			width,
			curve,
			skewY,
			sectionSize,
			sectionOffset,
			seatAlign = 'LEFT',
		} = props

		let { maxPosition, minPosition } = data
		maxPosition -= sectionOffset
		minPosition -= sectionOffset
		let position = 0
		position -= sectionOffset
		const positions = maxPosition - minPosition + 1
		const skew = new LinearFunction(
			skewY,
			skewY < 0 ? -skewY * (sectionSize - 1) : 0
		)
		let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
			width / 2,
			curve * 2,
		])
		let seatSpacing = new LinearFunction(SEAT_SIZE + SEAT_SPACING, SEAT_SPACING)
		position = minPosition
		let offset = 0
		switch (seatAlign) {
			case 'LEFT':
				position = position - minPosition
				break
			case 'RIGHT':
				offset = sectionSize - positions
				position = position - minPosition + offset
				break
			case 'CENTER':
				offset = (sectionSize - positions) / 2
				position = position - minPosition + offset
				break
		}
		return [
			seatSpacing.evaluateAt(position - 1),
			curveGen.evaluateAtStep(position)[1] +
				skew.evaluateAt(position) +
				SEAT_SIZE / 4,
		]
	}

	shouldComponentUpdate(nextProps, nextState) {
		const s = diff(nextState, this.state)
		if (Object.keys(s).length !== 0) {
			return true
		}
		return Object.keys(diff(nextProps, this.props)).length !== 0
	}

	renderSeats() {
		let {
			width,
			curve,
			skewY,
			data,
			seatAlign,
			sectionSize,
			showSeatNumber,
			textRotation = 0,
			onEditSelect,
			sectionOffset,
		} = this.props

		let { seats } = this.props.data
		const skew = new LinearFunction(
			skewY,
			skewY < 0 ? -skewY * (sectionSize - 1) : 0
		)
		let curveGen = new BeizerFunction([0, 0], [width, 0], sectionSize, [
			width / 2,
			curve * 2,
		])
		let seatSpacing = new LinearFunction(SEAT_SIZE + SEAT_SPACING, SEAT_SPACING)
		seats = seats.filter((seat) => !seat.killSeat) //dont render kill seats

		return seats.map((seat) => {
			let position = Row.getActualPosition(
				data,
				seat.position,
				seatAlign,
				sectionSize,
				sectionOffset
			)
			return (
				<svg
					key={seat.id}
					x={seatSpacing.evaluateAt(position)}
					y={curveGen.evaluateAtStep(position)[1] + skew.evaluateAt(position)}
					width={SEAT_RADIUS * 2}
					height={SEAT_RADIUS * 2}
				>
					<Seat
						onEditSelect={onEditSelect}
						textRotation={textRotation}
						showSeatNumber={showSeatNumber}
						isKillSeat={seat.killSeat}
						isInCart={seat.inCart}
						selected={!!this.props.selectedSeats[seat.id]}
						{...this.props}
						data={seat}
					/>
				</svg>
			)
		})
	}

	render() {
		return this.renderSeats()
	}
}
