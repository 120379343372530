import React from "react";
import {EDIT_NUB_SIZE,UnitConversion} from "components/seatingChart/shared/SeatingChartConfig";
import {EditableObject} from "components/seatingChart/shared/objects/EditableObject";
import {EditorConstants} from 'components/seatingChart/editor/EditorConstants';
import {OtColors} from 'util/OtColors';

const { ACTION_TOOLS } = EditorConstants;

export class RectangleObject extends EditableObject
{
  static getAbsoluteBoundingRect(object) {
    const {
      posX: x,
      posY: y,
      width,
      height
    } = object;

    return UnitConversion.convertRectangleToPixels({
        x,
        y,
        width,
        height,
        center:[
          x + width / 2,
          y + height / 2
        ]
    });
  }

  static getProperties() {
    return [
      EditorConstants.ACCESSORS.FILL,
      EditorConstants.ACCESSORS.STROKE,
      EditorConstants.ACCESSORS.STROKE_WIDTH,
      EditorConstants.ACCESSORS.OPACITY,
      EditorConstants.ACCESSORS.ROTATION
    ];
  }

  moveControlPoint(state, editMode) {
    const {
      data,
      data: {
        posX, posY, width, height
      },
      selectedTool,
      onPropertyChange
    } = this.props;

    let { dX, dY } = state;
    dX = UnitConversion.xPixelsToUnits(dX);
    dY = UnitConversion.yPixelsToUnits(dY);

    if (selectedTool.action === ACTION_TOOLS[1]
      && typeof onPropertyChange==="function")
    {
      let newData = null;
      switch(editMode) {
        case "TOP_LEFT":
          newData = {
            posX: posX + dX,
            posY: posY + dY,
            width: width - dX,
            height: height - dY
          };
          break;
        case "TOP_MIDDLE":
          newData = {
            posY: posY + dY,
            height: height - dY
          };
          break;
        case "TOP_RIGHT":
          newData = {
            posY: posY + dY,
            width: width + dX,
            height: height - dY
          };
          break;
        case "MIDDLE_LEFT": 
          newData = {
            posX: posX + dX,
            width: width - dX
          }; break;
        case "MIDDLE_RIGHT":
          newData = {
            width: width + dX
          };
          break;
        case "BOTTOM_LEFT": 
          newData = {
            posX: posX + dX,
            width: width - dX,
            height: height + dY
          };
          break;
        case "BOTTOM_MIDDLE":
          newData = {
            height: height + dY
          };
          break;
        case "BOTTOM_RIGHT":
          newData = {
            width: width + dX,
            height: height + dY
          };
          break;
        }
        if (!newData) {
          return false;
        }
        onPropertyChange(data, newData, this);
    }
    return true;
  }

  convertUnits() {
    const {
      data: {
        width = 0,
        height = 0
      }
    } = this.props;

    const {
      posX = 0,
      posY = 0
    } = this.getPosition();

    return {
      posX: UnitConversion.xUnitsToPixels(posX),
      posY: UnitConversion.yUnitsToPixels(posY),
      width: UnitConversion.xUnitsToPixels(width),
      height: UnitConversion.yUnitsToPixels(height) 
    };
  }

  renderEditingNubs() {
    const {
      selectedTool,
      nub_attributes,
      data
    } = this.props;

    const {
      posX,
      posY,
      width,
      height
    } = this.convertUnits();

    return (
      <g className="editIcons">
        <circle
          className={`object_nub nwse`}
          onMouseDown={(e) => this.editObject("TOP_LEFT")}
          cx={posX}
          cy={posY}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ns`}
          onMouseDown={(e) => this.editObject("TOP_MIDDLE")}
          cx={posX + width / 2}
          cy={posY}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nesw`}
          onMouseDown={(e) => this.editObject("TOP_RIGHT")}
          cx={posX + width}
          cy={posY}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ew`}
          onMouseDown={(e) => this.editObject("MIDDLE_LEFT")}
          cx={posX}
          cy={posY + height / 2}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ew`}
          onMouseDown={(e) => this.editObject("MIDDLE_RIGHT")}
          cx={posX + width}
          cy={posY + height / 2}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nesw`}
          onMouseDown={(e) => this.editObject("BOTTOM_LEFT")}
          cx={posX}
          cy={posY + height}
          {...nub_attributes}
        />
        <circle
          className={`object_nub ns`}
          onMouseDown={(e) => this.editObject("BOTTOM_MIDDLE")}
          cx={posX + width / 2}
          cy={posY + height}
          {...nub_attributes}
        />
        <circle
          className={`object_nub nwse`}
          onMouseDown={(e) => this.editObject("BOTTOM_RIGHT")}
          cx={posX + width}
          cy={posY + height}
          {...nub_attributes}
        />
      </g>
    );
  }

  render() {
    const {
      data,
      isContextual,
      isSelected,
      selectedTool
    } = this.props;

    let {
      posX,
      posY,
      width,
      height
    } = this.convertUnits();

    if (width < 0) {
      posX += width;
      width *= -1;
    }
    if ( height < 0 ) {
      posY += height;
      height *= -1;
    }

    return (
      <g transform={`rotate(${data.rotation}, ${posX + width / 2}, ${posY + height / 2})`}>
        <rect
          id={data.id}
          x={posX}
          y={posY}
          width={width}
          height={height}
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
          strokeWidth={data.strokeWidth}
          className="ot_rectObject chart_object"
          style={{
            opacity: data.opacity / 100
          }}
        ></rect>
        {isSelected
          && selectedTool.action === ACTION_TOOLS[1]
          && this.renderEditingNubs()}
      </g>
    );
  }
}
