import React from 'react';
import 'sass/components/common/containers/_dropContainer.scss';

let timeOut = null;

export default class DropContainer extends React.PureComponent {
  state = {
    activeButton: this.props.clickAndToggle,
    isOpen: false,
    posY: this.props.posY,
    isMobile: false
  }

  componentDidMount() {
    this.handleBgdLock(false)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOut );
    window.removeEventListener('touchstart', this.handleClickOut );
    let menu = document.getElementById(`dropMenu-${this.props.id}`);
    let button = document.getElementById(`dropButton-${this.props.id}`);

    if (button) button.removeEventListener('mouseleave', this.handleClickOut);
    if (menu) menu.removeEventListener('mouseleave', this.handleClickOut);
  }

  setListeners = () => { //console.log('adding');
    if (!this.props.clickAndToggle) {
      let button = document.getElementById(`dropButton-${this.props.id}`);
      button.addEventListener('mouseleave', this.handleLeaveButton);
    }
  }

  handleBgdLock = (value) => {
    let { posY } = this.state;
    let main = document.getElementById("main");
    if (value) {
      main.setAttribute("style",
      `overflow: hidden; position: fixed; top : -${posY}`);
    } else {
      document.body.scrollTop  = posY;
      window.scroll(0, posY);
    }
  }

  handleClickOut = (e) => {
    if (this.state.isOpen) this.toggleMenu(e, false);
  }


  handleLeaveButton = () => {
    this.setState({ activeButton: false }, () => {
      window.addEventListener('click', this.handleClickOut );
      if ( !this.state.isMobile ) {
        window.addEventListener('touchstart', this.handleClickOut  );
      }
    });
  }

  removeListeners = () => {
    if (!this.props.clickAndToggle) {
      let button = document.getElementById(`dropButton-${this.props.id}`);
      button.removeEventListener('mouseleave', this.handleLeaveButton);
    }
    window.removeEventListener('click', this.toggleMenu );
    if ( !this.state.isMobile ) {
      window.removeEventListener('touchstart', this.toggleMenu );
    }
  }

  setAutoClose = (isOpen = this.state.isOpen) => {
    if (isOpen ) {  // handles auto-close
      timeOut = setTimeout( () => {
        this.toggleMenu()
      }, 36000);
    } else clearTimeout(timeOut);
  }

  toggleMenu = (e, isOpen = !this.state.isOpen ) => {
    if (e && e.target) {
      e.preventDefault();
    }

    this.setState({ activeButton: isOpen, activeMenu: false, isOpen }, () => {
      if ( isOpen ) {
        this.setListeners();
        if (this.props.autoClose) {
          this.setAutoClose(isOpen);
        }

      } else {
        this.removeListeners();
        if (this.props.onClose) this.props.onClose();
      }

    });
    window.removeEventListener('click', this.handleClickOut );
    if ( !this.state.isMobile ) {
      window.removeEventListener('touchstart', this.handleClickOut  );
    }
  }

  render() {
    let { alignMenu = 'right', className, dropDirection, isMobile, isTablet, slideMenu, tag = 'div'} = this.props;
    let { isOpen } = this.state;
    let TagName = tag;

    const children = React.Children.toArray(this.props.children);
    let button  = children[0];
    let menu = children[1];

    let containerClass = 'ot_ci_dropContainer';
    if (className) containerClass += ` ${className}`;
    if (slideMenu && isOpen) containerClass += ` active`;

    let direction = 'down';
    if (dropDirection) direction = dropDirection.toLowerCase();
    let placeholderClass = `drop_placeholder ${direction} ${isOpen ? 'active' : 'close'}`;
    if (slideMenu) placeholderClass += ` slideMenu `;
    else placeholderClass += ` dropMenu `;

    let posAccessor = slideMenu ? 'translateX' : 'translateY';
    let rightMenu = slideMenu && direction === 'left';
    let posSymbol = rightMenu ? '' : "-";

    let style = Object.assign({}, {[alignMenu]: 0}, this.props.style);

    return (
        <TagName className={`${containerClass} `}>
          {React.cloneElement(button, {
            id: `dropButton-${this.props.id}`,
            onClick: button.props.onClick ? button.props.onClick : (e) => this.toggleMenu(e, !this.state.isOpen),
            className: `${button.props.className} ${isOpen ? 'active' : ''}`
          })}
          <div
              className={placeholderClass}
              style={{
                height: 'auto',
                maxHeight: `${isOpen ? 'none' : '0px'}`,
                opacity: `${isOpen ? 1 : 0}`,
                ...style
              }}
          >
            <div>
              <div
                  className={'drop_menuContainer'}
                  style={{
                    transform: `${posAccessor}(${posSymbol}${rightMenu ? '100%' : '0%'})`,
                    opacity: `${isOpen ? 1 : 0}`,
                    backgroundColor: `${isMobile ? 'white' : 'transparent'}`,
                    width: `${isTablet ? '30vw' : ''}`,
                    height: 'auto',
                    overflow: `${isOpen ? 'visible' : 'hidden'}`
                  }}
              >
                {menu}
              </div>
            </div>
          </div>
        </TagName>
    );
  }
}
