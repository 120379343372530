export default class DOMUtils {
	static get(selector) {
		return document.querySelector(selector)
	}

	static getParent(selector) {
		console.log('DOMselector', selector)
		let ele = document.querySelector(selector)
	}

	static offset(item) {
		if (typeof item === 'string') {
			item = DOMUtils.get(item)
		}

		let ret = { top: 0, left: 0 }
		if (!item) {
			return ret
		}
		let left = 0,
			top = 0
		do {
			if (!isNaN(item.offsetLeft)) {
				left += item.offsetLeft
			}
			if (!isNaN(item.offsetTop)) {
				top += item.offsetTop
			}
		} while ((item = item.offsetParent))
		return { top, left }
	}

	static outerHeight(elm, includeMargin) {
		if (typeof elm === 'string') {
			elm = DOMUtils.get(elm)
		}
		var elmHeight, elmMargin
		if (document.all) {
			// IE
			elmHeight = parseInt(elm.currentStyle.height, 10)
			elmMargin =
				parseInt(elm.currentStyle.marginTop, 10) +
				parseInt(elm.currentStyle.marginBottom, 10)
		} else {
			// Mozilla
			elmHeight = parseInt(
				document.defaultView
					.getComputedStyle(elm, '')
					.getPropertyValue('height'),
				10
			)
			elmMargin =
				parseInt(
					document.defaultView
						.getComputedStyle(elm, '')
						.getPropertyValue('margin-top')
				) +
				parseInt(
					document.defaultView
						.getComputedStyle(elm, '')
						.getPropertyValue('margin-bottom')
				)
		}
		return elmHeight + (includeMargin ? elmMargin : 0)
	}

	static getMargin(item) {
		return DOMUtils.outerHeight(item, true) - DOMUtils.outerHeight(item)
	}

	static getWindowSize() {
		return [DOMUtils.getWindowWidth(), DOMUtils.getWindowHeight()]
	}

	static getWindowWidth() {
		return (
			window.innerWidth ||
			document.documentElement.clientWidth ||
			document.body.clientWidth
		)
	}

	static getWindowHeight() {
		return (
			window.innerHeight ||
			document.documentElement.clientHeight ||
			document.body.clientHeight
		)
	}

	static getDOMRect(selector) {
		let ele = DOMUtils.get(selector)

		if (ele) {
			let rect = ele.getBoundingClientRect()
			return rect
		}
	}
	// scrollDim - dimensions include overflowed area
	static getScrollDim(selector) {
		// returns width , height
		let ele = DOMUtils.get(selector)

		if (ele) {
			return { width: ele.scrollWidth, height: ele.scrollHeight }
		}
	}

	static getClientDim(selector) {
		// returns width , height
		let ele = DOMUtils.get(selector)

		if (ele) {
			return { width: ele.clientWidth, height: ele.clientHeight }
		}
	}

	static getClientRect(id) {
		// console.log('GET CLIENT RECT',id);
		let ele = document.getElementById(id)
		// console.log(ele);
		if (ele) return ele.getBoundingClientRect()
	}

	static isMobile() {
		let windowWidth =
			window.innerWidth || window.clientWidth || window.clientWidth
		return windowWidth < 812
	}

	static isLandscape() {
		return (
			window.matchMedia('(orientation: landscape)').matches &&
			window.matchMedia('(min-width: 480px)').matches &&
			window.matchMedia('(max-width: 767px)').matches
		)
	}

	static isTablet() {
		return (
			window.matchMedia('(orientation: portrait)').matches &&
			window.matchMedia('(min-width: 768px)').matches &&
			window.matchMedia('(max-width: 991px)').matches
		)
	}
}
