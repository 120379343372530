import DOMUtils from 'util/DOMUtils'
/* TODO:media not updating!!! use context/media + 2 different variables */
let isMobile = DOMUtils.isMobile()
let isTablet = DOMUtils.isTablet()
let isLandscape = DOMUtils.isLandscape()

function getWindowWidth() {
	return (
		window.innerWidth ||
		document.documentElement.clientWidth ||
		document.body.clientWidth
	)
}

function getWindowHeight() {
	return (
		window.innerHeight ||
		document.documentElement.clientHeight ||
		document.body.clientHeight
	)
}

let [windowWidth, windowHeight] = [getWindowWidth(), getWindowHeight()]
let mainContainer = 1080
let minNavBar = 50
let minFooter = isMobile ? 50 : 70
let package_header = 80
let calendar_header = 150

export const ChartConst = {
	DEFAULT_SCALE_STEP: 0.25,
	DEFAULT_ZOOM_CLICKS: 4, // number of zoom clicks between home and mix/max zoom level
	MIN_ZOOM_SCALE: 0.25,
	MAX_ZOOM_SCALE: 2,
	DEFAULT_WIDTH: windowWidth, //800,
	DEFAULT_HEIGHT: windowHeight, //800,
	BUTTON_SIZE: 35,
	TOOLBAR_HEIGHT: 60,
	WIDGET_WIDTH: 400,
	EDITOR_HEADER_HEIGHT: 60,
	EDITOR_TOOLBAR_WIDTH: 80,
	EDITOR_WIDGET_WIDTH: 350,
	EDITOR_PADDING: 0,
	ADMIN_FRAME_HEIGHT: 0, //75.91,
	CHART_PADDING: 40,
	CHART_PADDING_MOBILE: 10,
	CHART_LEGEND_MIN: 255,
	CHART_LEGEND_MAX: 360,
}

export const TicketTypePopUpConst = {
	POPUP_WIDTH: 300,
	POPUP_HEIGHT: 320,
	SEAT_OFFSET: 5,
}

export const Layout = {
	button_min: 40,
	fixed_page_height: isMobile ? windowHeight - minNavBar : undefined,
	min_footer_height: minFooter,
	min_navbar_height: minNavBar,
	max_navbar_height: 100,
	padding: 20,
	padding_bottom: 40,
	padding_min: 5,
}

export const StoreConst = {
	title_height: 60,
	Packages: {
		card_header: isMobile ? 40 : 35,
		card_paddingX: isMobile ? 20 : 40,
		card_paddingY: isMobile ? 15 : 20,
		card_seating_options: isMobile ? 94 : 330,
		card_steps: {
			1: 'productions',
			2: 'performances',
			3: 'details',
		},
		details_height: isMobile ? 70 : 175,
		group_mobile_height: isMobile ? 130 : 170,
		group_seating_options: isMobile ? 150 : 330,
		package_header_height: package_header,
		performance_button_height: 30,
		performance_slot: {
			width: 125,
			height: 50,
		},
		production_size: isMobile ? 94 : 124,
		production_size_selected: isMobile ? 120 : 180,
		production_padding: 7, // 5px padding + 2px border
		seating_options: {
			1: 'BEST',
			2: 'CHOOSE',
			3: 'SAME',
		},
		ticket_container: {
			width: isMobile ? windowWidth : mainContainer,
			height: isMobile
				? windowHeight - minNavBar - minFooter - package_header
				: 230,
		},
	},
}

export const CalendarConst = {
	calendar_container: {
		width: windowWidth,
		height: 300,
		extendedHeight: 370,
	},
}

export const FeatureFlagConst = {
	IS_CHECKOUT_CONTINUE_SHOPPING_BUTTON_ACTIVE:
		'checkoutContinueShoppingButtonActive',
	IS_EMPTY_CART_MESSAGE_ON_CI: 'emptyCartMessageOnCI',
	IS_CART_SPLITTED_CI: 'cartSplittedCI',
	IS_NEW_CUSTOMIZABLE_CALENDAR_LIST_VIEW_SELECTION:
		'newCustomizableCalendarListViewSelection',
	IS_ADD_IMAGE_TO_PACKAGES_OPTION_ENABLED: 'addImageToPackagesOptionEnabled',
	IS_XCOVER_OPT_OUT_ENABLED: 'xcoverOptOutEnabled',
	IS_CALENDAR_STOREFRONT_NAME_ENABLED: 'calendarStorfrontNameEnabled',
}

export const CustomizedViewConst = {
	IS_CALENDAR_VIEW_SELECTED: 'isCalendarViewSelected',
	IS_LIST_VIEW_SELECTED: 'isListViewSelected',
}
