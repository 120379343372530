var _type=Symbol("actionType");
var _data=Symbol("data");
var _context=Symbol("context");
var _event=Symbol("event");

export default class BaseAction {
    constructor(actionType,event,data,context) {
        this[_type]=actionType;
        this[_event]=event;
        this[_data]=data;
        this[_context]=context;
    }
    get event() {return this[_event];}
    get type() {return this[_type];}
    get actionType() {return this[_type];}
    get data() {return this[_data];}
    get context() {return this[_context];}
}

BaseAction.EVENT_TYPE="BASE_ACTION";
BaseAction.URL_CHANGE_ACTION="URL_CHANGE_ACTION";
BaseAction.ERROR_EVENT_TYPE="ERROR_ACTION";
BaseAction.WARNING_EVENT_TYPE="WARNING_ACTION";
BaseAction.NOTIFICATION_EVENT_TYPE="NOTIFICATION_ACTION";
BaseAction.DELIVERY_METHOD_EVENT_TYPE="DELIVERY_METHOD_ACTION";
BaseAction.CART_EVENT_TYPE="CART_ACTION";
BaseAction.CONTACT_DETAILS_EVENT_TYPE="CONTACT_DETAILS_ACTION";
BaseAction.PAYMENT_INFO_ACTION="PAYMENT_INFO_ACTION";
BaseAction.CHECKOUT_INFO_ACTION="CHECKOUT_INFO_ACTION";
BaseAction.CHECKOUT_STEP_CHANGE_ACTION="CHECKOUT_STEP_CHANGE_ACTION";
BaseAction.CHECKOUT_ACTION="CHECKOUT_ACTION";
BaseAction.CHECKOUT_SCROLL_ACTION="CHECKOUT_SCROLL_ACTION";
BaseAction.CONTACT_DETAILS_UPDATE_ACTION="CONTACT_DETAILS_UPDATE_ACTION";
BaseAction.ZIP_CODE_LOOKUP="ZIP_CODE_LOOKUP";
BaseAction.VALIDATION_STATE_CHANGE_ACTION="VALIDATION_STATE_CHANGE_ACTION";
BaseAction.CLIENT_CONTEXT_ACTION="CLIENT_CONTEXT_ACTION";
BaseAction.CLIENT_NOT_FOUND_ACTION="CLIENT_NOT_FOUND_ACTION";
BaseAction.SUBMIT_GUEST_FORM="SUBMIT_GUEST_FORM";
BaseAction.PERFORMANCE_ACTION="PERFORMANCE_ACTION";
BaseAction.LOAD_MENU_ACTION="LOAD_MENU_ACTION";
BaseAction.TOGGLE_SHOPPING_CART_MENU_ACTION="TOGGLE_SHOPPING_CART_MENU_ACTION";
BaseAction.PRODUCTION_ACTION="PRODUCTION_ACTION";
BaseAction.CALENDAR_PRODUCTIONS_LOADED="CALENDAR_PRODUCTIONS_LOADED";
BaseAction.CART_VALIDATION_SUCCESS="CART_VALIDATION_SUCCESS";
BaseAction.CART_VALIDATION_SUCCESS_MP="CART_VALIDATION_SUCCESS_MP";
BaseAction.ELEMENT_VALIDATION_STATE_CHANGE="ELEMENT_VALIDATION_STATE_CHANGE";
BaseAction.MASTERPASS_CHECKOUT="MASTERPASS_CHECKOUT";
BaseAction.MASTERPASS_RESET="MASTERPASS_RESET";
BaseAction.ROUTE_CHANGE_ACTION="ROUTE_CHANGE_ACTION";
BaseAction.INLINE_ERROR_TYPE="INLINE_ERROR_TYPE";
BaseAction.RESET_CART="RESET_CART";

BaseAction.REQUEST_PRODUCTION_LIST="REQUEST_PRODUCTION_LIST";
BaseAction.PRODUCTION_LIST_LOADED="PRODUCTION_LIST_LOADED";
