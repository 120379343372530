import React from "react";
import {
  UnitConversion
} from "components/seatingChart/shared/SeatingChartConfig";
import {SvgEditableLabel} from "components/form/elements/EditableLabel";
import {EditableObject} from "components/seatingChart/shared/objects/EditableObject";
import {EditorConstants} from 'components/seatingChart/editor/EditorConstants';

const {
  ACTION_TOOLS
} = EditorConstants;

export class LabelObject extends EditableObject
{
  label = React.createRef()

  getLabel = () => this.label && this.label.current ?
    this.label.current : null;

  getLabelBounds = () => this.getLabel() !== null ?
    this.getLabel().getBBox() : null;
  
  static getAbsoluteBoundingRect(object) {
    const {
      posX: x,
      posY: y
    } = object;

    return {
      ...UnitConversion.convertRectangleToPixels({
        x,
        y,
        width: 0,
        height: 0,
        center:[
          x, y
        ]
      }),
      // we return the width and height as pixels based on fontSize assuming the fontSize represents pixels. 
      width: object.text.length * object.fontSize * 0.5,
      height: object.fontSize,
    };
  }

  static getProperties() {
    return [
      EditorConstants.ACCESSORS.FILL,
      EditorConstants.ACCESSORS.FONT_SIZE,
      EditorConstants.ACCESSORS.FONT_WEIGHT,
      EditorConstants.ACCESSORS.ROTATION
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data,
      selectedTool,
      onRemoveObject
    } = nextProps;

    const editing = selectedTool 
      && selectedTool.action === ACTION_TOOLS[1];

    if (!editing && data && (!data.text || data.text.length === 0) && !!onRemoveObject) {
      onRemoveObject(data);
    }
  }

  onBlur = () => {
    const {
      data,
      onRemoveObject
    } = this.props;
    if (data && (!data.text || data.text.length === 0) && !!onRemoveObject) {
      onRemoveObject(data);
    }
  }

  onChange = (value) => {
    const {
      data,
      selectedTool,
      onPropertyChange,
    } = this.props;

    if (selectedTool
      && selectedTool.action === ACTION_TOOLS[1]
      && typeof onPropertyChange === "function") {
      onPropertyChange(data, { text: value }, this);
    }
  }

  renderEditingNubs() {
    const { nub_attributes } = this.props;
    const { posX = 0, posY = 0 } = this.getPosition();
    const cx = UnitConversion.xUnitsToPixels(posX);
    const cy = UnitConversion.yUnitsToPixels(posY);

    return (
      <g className="editIcons">
        <circle
          className="object_nub"
          cx={cx}
          cy={cy}
          {...nub_attributes}
        />
      </g>
    );
  }

  render() {
    const {
      data,
      data: {
        text = ""
      },
      isSelected,
      selectedTool
    } = this.props;

    const editing = selectedTool 
      && selectedTool.action === ACTION_TOOLS[1];

    const {
      posX = 0,
      posY = 0
    } = this.getPosition();

    const x = UnitConversion.xUnitsToPixels(posX);
    const y = UnitConversion.yUnitsToPixels(posY);

    const style={
      opacity: data.opacity / 100,
      strokeWidth: 0,
      fill: data.fill,
      stroke: data.stroke,
      fontSize: data.fontSize,
      fontWeight: data.fontWeight
    };

    return (
      <g
        ref={this.label}
        className="label"
        transform={`rotate(${data.rotation}, ${x}, ${y})`}
        key={`${data.id}`}
      >
        <SvgEditableLabel
          disableEditing={!editing}
          onChange={this.onChange}
          value={text}
          style={style}
          data={data}
          x={x}
          y={y}
          onClick={this.props.onClick}
          onBlur={this.onBlur}
        />
        {isSelected && editing && this.renderEditingNubs()}
      </g>
    );
  }
}
