import React, { useContext, useEffect, useState } from 'react';
// Styled components
import styled from 'styled-components';
// Theme and colors
import { theme, colors } from 'theme';
// Star icon
import { SvgStar } from 'components/common/icons/SvgIcons'
// Context
import { TicketContext } from './ticketContext/TicketContext';

const Ticket = styled.div`
	padding: ${theme.padding.lg};

	background-color: ${({ selected }) => selected ? colors.surfaces.alternate : colors.white};
	transition: 200ms ease-in-out;

	border-top: 1px solid ${colors.borders.black};

	cursor: pointer;
	&:hover {
		background-color: ${({ selected }) => selected ? colors.surfaces.alternate : colors.surfaces.background};
	}
`

const TicketHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;

	margin-bottom: ${theme.margin.smmd};
`

const TicketTitleAndPrice = styled.div`
	font-size: ${theme.fontSizes.md};
	font-weight: ${theme.fontWeight.semiBold};

	display: flex;
`

const TicketIcon = styled.div`
	margin-left: ${theme.margin.xxs};

	width: fit-content;
`

const TicketDescription = styled.p`
	font-size: ${theme.fontSizes.xs};

	width: 200px;
`

const TicketType = ({ ticket, title = 'Full price', isPromo = false, isFirstTicket = true }) => {
	const ticketContext = useContext(TicketContext);
	const { price, convenienceFee, priceIncludingFees } = ticket;

	useEffect(() => {
		if (!ticketContext.ticketSelectedOnce && isFirstTicket) {
			ticketContext.setSelectedTicket(ticket);
			ticketContext.setTicketSelectedOnce(true);
		}
	}, [])

	
	const addDecimals = (number) => {
		return Number(number).toFixed(2);
	}

	const handleClick = () => {
		ticketContext.setSelectedTicket(ticket);
	}

	return (
		<Ticket onClick={handleClick} selected={ticketContext?.selectedTicket?.ticketTypeId === ticket?.ticketTypeId}>

			<TicketHeader>
				<TicketTitleAndPrice>
					{title}
					{isPromo && <TicketIcon>
						<SvgStar color="black" size="10" />
					</TicketIcon>}
				</TicketTitleAndPrice>

				<TicketTitleAndPrice>
					${addDecimals(price)}
				</TicketTitleAndPrice>
			</TicketHeader>
			
			<TicketDescription>
				Includes ${addDecimals(convenienceFee)} facility fee + ${addDecimals(price)} convenience charge
			</TicketDescription>

		</Ticket>
	);
}


export default TicketType