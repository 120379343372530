import { ChartConst } from 'util/Constants';

const {
  ADMIN_FRAME_HEIGHT,
  // DEFAULT_SCALE_STEP,
  // EDITOR_HEADER_HEIGHT,
  EDITOR_PADDING,
  EDITOR_TOOLBAR_WIDTH,
  EDITOR_WIDGET_WIDTH
} = ChartConst;

let seatFill = 11;

/**
 * The size of the outline of a seat.
 */
export const SEAT_STROKE=1;

/**
 * the radius of a circular seat. This is 1/2 the size of the seat.
 */
export const SEAT_RADIUS=seatFill+SEAT_STROKE;

/**
 * The amount of horizontal space between seats
 */
export const SEAT_SPACING = 4;//4, previously 6
/**
 * The size of a seat on the seating chart
 */
export const SEAT_SIZE=2*SEAT_RADIUS;
/**
 * The amount of vertical space between seats
 */
export const SEAT_SPACING_Y= 5; //5, previously 12

/**
 * The larges scale a seating chart can be.
 */
export const MAX_ZOOM=3;
/**
 * The zoom increment/decrement for  a single zoom action. This does not apply to the editor.
 */
export const ZOOM_STEP=1;

/**
 * The smallest scale a (client facing) seating chart can be. This does not apply to the editor.
 */
export const MIN_ZOOM=.025;

/**
 * The size of the seating chart editor nubs for resizing
 */
export const EDIT_NUB_SIZE=10;

/**
 * The default padding for th editor
 */
export const PADDING = {
  top: EDITOR_PADDING,
  right: EDITOR_PADDING + EDITOR_WIDGET_WIDTH,
  bottom: EDITOR_PADDING,
  left: EDITOR_PADDING
};

/**
 * The default view port size
 */
export const VIEW_PORT_SIZE = [
  window.innerWidth - EDITOR_TOOLBAR_WIDTH,
  window.innerHeight - ADMIN_FRAME_HEIGHT
];

/**
 * The units for position coordinates stored in the database are arbitrary numbers, designed
 * such that if something changes in spacing or sizing, the seating charts will not be affected
 * Right now, the arbitrary unit is defined as the amount of space a seat takes up in pixels. This
 * is defined in the constants above. This class simply converts the arbitrary units used in storage
 * to pixels for rendering. It also does the reverse conversion for saving.
 */
export class UnitConversion {
    /**
     * @static xPixelsToUnits - Converts an x coodinate in pixels to one in db unit
     * @param the x coordinate in pixels
     * @return {Number} the DB value of the x coordinate
     */

    static xPixelsToUnits(xpoint) {
        return xpoint/(SEAT_SIZE+SEAT_SPACING);
    }
    /**
     * @static yPixelsToUnits - Converts a y coodinate in pixels to one in db unit
     * @param the y coordinate in pixels
     * @return {Number} the DB value of the y coordinate
     */
    static yPixelsToUnits(ypoint) {
        return ypoint/(SEAT_SIZE+SEAT_SPACING_Y);
    }

    /**
     * @static xUnitsToPixels - Converts an x coodinate in DB units to pixels for rendering
     * NOTE: due to scaling considerations, the pixels represent the svg cartesion plane coordinate, not screen pixels
     * @param xpoint the x coordinate in db units
     * @return {Number} the pixels on the x axis of this db point
     */
    static xUnitsToPixels(xpoint) {
        return xpoint*(SEAT_SIZE+SEAT_SPACING);
    }
    /**
     * @static yUnitsToPixels - Converts a y coodinate in DB units to pixels for rendering
     * NOTE: due to scaling considerations, the pixels represent the svg cartesion plane coordinate, not screen pixels
     * @param ypoint the y coordinate in db units
     * @return {Number} the pixels on the y axis of this db point
     */
    static yUnitsToPixels(ypoint) {
        return ypoint*(SEAT_SIZE+SEAT_SPACING_Y);
    }

    /**
     * @static convertUnitToPoint - converts a point from db units to pixels
     * @param  {type} point the point in db units to convert to pixels
     * @return {Array} a 2-arry [x,y] point in db units
     */
    static convertUnitToPoint(point) {
        return [UnitConversion.xUnitsToPixels(point[0]),UnitConversion.yUnitsToPixels(point[1])];
    }
    /**
     * @static convertPointToUnit - converts a point to db units
     * @param  {Array} point a 2-ary aray of [x,y] where and y are numeric coordinates in pixels
     * @return {Array} a 2-arry [x,y] point in db units
     */

    static convertPointToUnit(point) {
        return [UnitConversion.xPixelsToUnits(point[0]),UnitConversion.yPixelsToUnits(point[1])];
    }
    /**
     * @static convertPointsToUnit - Converts a list of points in pixels to db units
     * @param  {Array} ...points the points to convert to db units
     * @return {Array} an array containing 2-ary array of input points in db units
     */
    static convertPointsToUnit(...points) {
        return points.map(point=>UnitConversion.convertPointToUnit(point));
    }

    /**
     * @static convertRectangleToPixels - Converts a rectangle in db units to pixels for rendering
     * @param  {type} rect description
     * @return {Rectangle} the input rectangle with units converted
     */
    static convertRectangleToPixels(rect) {
        return {
            x:UnitConversion.xUnitsToPixels(rect.x),
            y:UnitConversion.yUnitsToPixels(rect.y),
            width:UnitConversion.xUnitsToPixels(rect.width),
            height:UnitConversion.yUnitsToPixels(rect.height),
            center:UnitConversion.convertUnitToPoint(rect.center)
        };
    }
}
