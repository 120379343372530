import React, { useState, useContext, useEffect } from "react";

// Libraries
import styled from "styled-components";

// Theme
import { theme, colors } from "../../../../../theme";

// Context
import { TicketContext } from "../ticketContext/TicketContext";

// Ticket types
import { types } from "../ticketContext/TicketContext";

const Container = styled.div`
	padding: ${theme.padding.xl} ${theme.padding.xxl};

	display: flex;
	justify-content: space-between;
`

const Button = styled.div`
	font-weight: ${theme.fontWeight.regular};
	font-size: ${theme.fontSizes.sm};
	color: ${colors.blue.primary};

	padding: ${theme.padding.sm} ${theme.padding.xxxl};

	background-color: ${colors.white};
	border: 1px solid ${colors.blue.primary};
	border-radius: ${theme.borderRadius.md};

	cursor: pointer;

	transition: 200ms ease-in-out;

	${(props) => {
		if (props.selected) {
			return `
				background-color: ${colors.blue.primary};
				color: ${colors.white};
			`
		}
	}}
`

const TicketAccordionButton = () => {
	const ticketContext = useContext(TicketContext)

	const [selectedType, setSelectedType] = useState(ticketContext.selectedTicketType || 1)

	const handleClick = (type) => {
		setSelectedType(type)

		ticketContext.setSelectedTicketType(type)
	}

	const getFormattedKeys = (obj) => {
		const keys = Object.keys(obj);
		const formattedKeys = keys.map((key) => key.charAt(0).toUpperCase() + key.slice(1));
		return formattedKeys;
	};

	return (
		<Container>
			{getFormattedKeys(types).map(type => {
				return <Button key={type} onClick={() => handleClick(types[type.toLowerCase()])} selected={selectedType === types[type.toLowerCase()]}>{type}</Button>
			})}
		</Container>
	);
};

export default TicketAccordionButton;