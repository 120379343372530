import React from "react";
import { Callout } from 'components/common/containers/Callout';
import DOMUtils from "util/DOMUtils"

let offset = 0;

const TOOLTIP_HEIGHT = 30


/*
	  PROPS          DEFAULT         USE
	  ==================================================================================================
	  align         'start'         Alignment in reference to the trigger Element.
	                                Maybe modified by the trigger element dataset: data-align
	                                Options: 'start', 'center', 'end' (string)

    className     'myclass'       Additional style class ->  .ot_tooltip.my_custom_class { .ot_callout { ...custom rules }}

    offsetX          0            Horizonatl Gap between tigger element and toolip (number)

    offsetXY          0           Vertical Gap between tigger element and toolip (number)

    position      'bottom'        Tooltip position in reference to the trigger element
                                  Maybe modified by the trigger element dataset: data-position
                                  Option: 'bottom', 'top', 'left', 'right' (string)

    type          'info'          controlls background. info = black //ToDo optional types: success, alert, prmary, etc
    ==================================================================================================
*/

export class OTTooltip extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			align: props.align ? props.align : 'start',
			data: null,
			isActive: false,
			position: props.position ? props.position : 'bottom',
			targetDOM: null,
			targetId: null,
		};
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
		this.handleMouseOver = this.handleMouseOver.bind(this);
		this.handleTooltipOut = this.handleTooltipOut.bind(this);
		this.removeTooltip = this.removeTooltip.bind(this);
	}

	componentDidMount() {
		document.addEventListener('scroll', this.handleMouseLeave);
	}

	componentWillUnmount() {
		document.removeEventListener('scroll', this.handleMouseLeave);
		if (this.state.targetId) {
			this.removeTargetListener();
		}

	}

	getPosition = () => {  //console.log('GETPOSITION');
		let { offsetX = 0, offsetY = 0 } = this.props;
		let { align, position, targetDOM } = this.state;
		let posX = targetDOM.left;
		let posY = targetDOM.top;

		if (align === 'center') {
			posX += targetDOM.width/2;
		} else if (align === 'end') {
			posX = targetDOM.right;
			posY = targetDOM.bottom;
		}

		switch ( position.toLowerCase() ) {
			case 'top':
				return {x: posX - offsetX, y: targetDOM.top - offsetY};
			case 'left': return this.getPositionLeft();
			case 'bottom': return this.getPositionBottom();
			case 'right': return this.getPositionRight();
			default:
				return {x: targetDOM.left, y: targetDOM.bottom + offset};
		}
	};

	getPositionTop = () => {};

	getPositionRight = () => {
		let { offsetX = 0, offsetY = 0 } = this.props;
		let { align, targetDOM } = this.state;

		switch ( align ) {
			case 'start':
				return {x: targetDOM.right - offsetX, y: targetDOM.top - TOOLTIP_HEIGHT/2 + offsetY };
			case 'center':
				return {x: targetDOM.right - offsetX, y: targetDOM.top +  targetDOM.height/2 - TOOLTIP_HEIGHT/2 + offsetY };
			case 'end':
				return {x: targetDOM.right - offsetX, y: targetDOM.bottom - TOOLTIP_HEIGHT/2 + offsetY };
			default:
				return {x: targetDOM.right - offsetX, y: targetDOM.top +  targetDOM.height/2 - TOOLTIP_HEIGHT/2 + offsetY };
		}
	};

	getPositionBottom = () => { //console.log('BOTTOM');
		let { offsetX = 0, offsetY = 0 } = this.props;
		let { align, targetDOM } = this.state;

		switch ( align ) {
			case 'start':
				return {  x: targetDOM.left + offsetX,
									y: targetDOM.bottom + offsetY };
			case 'center':
				return {  x: targetDOM.left + targetDOM.width/2,
									y: targetDOM.bottom + offsetY };
			case 'end':
				return {  x: targetDOM.right + offsetX,
									y: targetDOM.bottom + offsetY };
			default:
				return {  x: targetDOM.left + offsetX,
									y: targetDOM.bottom + offsetY };
		}
	};

	getPositionLeft = () => { //console.log('POSITIONLEFT');
		let { offsetX = 0, offsetY = 0 } = this.props;
		let { align, targetDOM } = this.state;

		switch ( align ) {
			case 'start':
				return {x: targetDOM.left - offsetX, y: targetDOM.top - TOOLTIP_HEIGHT/2 + offsetY };
			case 'center':
			return {x: targetDOM.left - offsetX, y: targetDOM.top +  targetDOM.height/2 - TOOLTIP_HEIGHT/2 + offsetY };
			case 'end':
				return {x: targetDOM.left - offsetX, y: targetDOM.bottom - TOOLTIP_HEIGHT/2 + offsetY };
			default:
				return {x: targetDOM.left - offsetX, y: targetDOM.top +  targetDOM.height/2 - TOOLTIP_HEIGHT/2 + offsetY };
		}
	};

	handleMouseLeave(e) { // console.log('MOUSELEAVE');
		if (this.state.isActive ) { // DO NOT MERGE IFs!
			// if ( !positionInRange({x: e.pageX, y: e.pageY}, this.state.targetDOM) )
				this.removeTooltip();
		}
	}

	handleMouseOver(e) {
		let { id, dataset } = e.target;
		//console.log('MOUSE OVER', id, dataset);
		if ( !this.state.isActive && dataset.hint && id ) {
			let targetDOM = DOMUtils.getClientRect( id );

			this.setState( {
				align: dataset.align ? dataset.align : this.state.align,
				data: dataset.hint,
				isActive: true,
				position: dataset.position ? dataset.position : this.state.position,
				targetDOM: targetDOM,
				targetId: id
			}, () => {
				this.setTargetListener( id );

			} );
		}
	}

	handleTooltipOut() {
		setTimeout(() => {
			this.removeTooltip();
		}, 600);
	}

	removeTooltip() { // console.log('REMOVE TOOLTIP');
		this.setState( { data: null, isActive: false, targetDOM: null, targetId: null },
			() => {
				let { targetId } = this.state;

				if ( targetId) this.removeTargetListener( targetId );
			} );
	}

	removeTargetListener = (id = this.state.targetId) => { // console.log('REMOVE LISTENER');
		let target = DOMUtils.get(`#${id}`);
		if (target) {
			target.removeEventListener('mouseleave', this.handleMouseLeave);
			target.removeEventListener('mousedown', this.handleMouseLeave);
		}
	};

	setTargetListener = (id) => { // console.log('ADD LISTENER');
		let target = DOMUtils.get(`#${id}`);
		if (target) {
			target.addEventListener('mouseleave', this.handleMouseLeave);
			target.addEventListener('mousedown', this.handleMouseLeave);
		}
	};

	render() {
		let { align, isActive, data, position } = this.state;
		if ( !isActive ) return null;
		let { className, type = 'info', ...props } = this.props;
		let { x, y } = this.getPosition();

		return (
			<div className={
				`ot_tooltip  ${className ? className : ''}
				${isActive ? 'active' : ''}
				${position} ${align} ${type}`}
				style={{ top: `${y}px`, left: `${x}px`}}
				onMouseOut={this.handleTooltipOut}>
				<Callout role="tooltip" {...props} aria-label={data}>
					<p>{data}</p>
				</Callout>
			</div> );
	}
}
